import Dialogs from "@/constants/dialogs";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import { mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import { VForm, VTextField } from "vuetify/lib";
import { isRequired } from "@/helpers/validations";
import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";

const CampaignCreateDialog = Vue.extend({
  data: () => ({
    name: "",
    isFormValid: false,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.IS_DIALOG_OPEN]),
  },

  methods: {
    async handleSubmit() {
      const { isFormSubmited, data } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.CAMPAIGN_PREPARE,
        values: { name: this.name },
        actionId: Actions.CAMPAIGN_PREPARE,
      });

      if (isFormSubmited) {
        window.location.href = `${process.env.VUE_APP_CAMPAIGN_URL}/Edit/${data.idCampaign}`;
      }
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.CAMPAIGN_CREATE}
        title="New campaign"
        actions={[
          {
            id: Actions.CAMPAIGN_PREPARE,
            title: "Create",
            icon: "mdi-plus",
            action: () => this.handleSubmit(),
          },
        ]}
      >
        <VForm ref="form" vModel={this.isFormValid}>
          <VTextField vModel={this.name} label="Name" rules={[isRequired]} />
        </VForm>
      </Dialog>
    );
  },
});

export default CampaignCreateDialog;
