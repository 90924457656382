export const API_URL = process.env.VUE_APP_API_URL;
export const SSO_URL = process.env.VUE_APP_SSO_URL;
export const ADMINISTRATION_URL = `${API_URL}/Administration`;

export const MODULE_URL = `${ADMINISTRATION_URL}/Module`;
export const LINE_URL = `${ADMINISTRATION_URL}/Line`;
export const FLOW_URL = `${ADMINISTRATION_URL}/CallScript`;
export const GRAMMAR_URL = `${ADMINISTRATION_URL}/Grammar`;
export const RESOURCE_URL = `${ADMINISTRATION_URL}/Resource`;
export const ROLES_URL = `${ADMINISTRATION_URL}/Role`;
export const TOOLS_URL = `${ADMINISTRATION_URL}/Tools`;
export const TENANT_URL = `${ADMINISTRATION_URL}/Tenant`;
export const ASSETS_URL = `${API_URL}/Assets`;
export const HOME_URL = `${API_URL}/Home`;
export const STATISTICS_URL = `${API_URL}/Statistics`;

const ApiEndpoints = {
  // DataGrids
  AUDIT: `${ADMINISTRATION_URL}/Audit`,
  DIAL_MODULES: `${ADMINISTRATION_URL}/DialModules`,
  DIAL_MODULE_LINES: `${ADMINISTRATION_URL}/DialModuleLines`,
  DIAL_LOGS: `${ADMINISTRATION_URL}/DialLogs`,
  FLOW: `${ADMINISTRATION_URL}/CallScripts`,
  FLOW_VERSION_HISTORY: `${FLOW_URL}/Histories`,
  CAMPAIGNS: `${ADMINISTRATION_URL}/Campaigns`,
  GRAMMARS: `${ADMINISTRATION_URL}/Grammars`,
  USERS: `${ADMINISTRATION_URL}/Users`,
  TENANTS: `${ADMINISTRATION_URL}/Tenants`,
  PERMISSION_GROUPS: `${ADMINISTRATION_URL}/Roles`,
  SET_FAVORITE: `${ADMINISTRATION_URL}/SetFavorite`,

  // Assets
  AUDIO_RECORD: `${ASSETS_URL}/Record`,
  CSV_REPORTS_MODULE: `${ASSETS_URL}/GenerateCsvByDialModule`,
  CSV_REPORTS_LINE: `${ASSETS_URL}/GenerateCsvByDialModuleLine`,
  CSV_REPORTS_FLOW: `${ASSETS_URL}/GenerateCsvByCallScript`,
  CSV_TENANT_STATISTICS: `${ASSETS_URL}/GenerateCsvByTenant`,
  CSV_OVERVIEW_TENANT_STATISTICS: `${ASSETS_URL}/GenerateOverviewCsvByTenant`,
  DIAL_LOG_DETAIL: `${ASSETS_URL}/CallDetail`,

  // Modules
  MODULE_BASIC: `${MODULE_URL}/Basic`,
  MODULE_CALLBOT_SETTINGS: `${MODULE_URL}/CallbotSettings`,
  MODULE_REMOVE: MODULE_URL,
  MODULE_LINE_CREATE: `${MODULE_URL}/CreateLine`,
  MODULE_DEFAULT_LINE: `${MODULE_URL}/SetDefaultLine`,

  // Lines
  LINE_BASIC: `${LINE_URL}/Basic`,
  LINE_CALLBOT_SETTINGS: `${LINE_URL}/CallbotSettings`,
  LINE_TOGGLE: `${LINE_URL}/SetEnableLine`,
  LINE_SET_MODULE: `${LINE_URL}/Move`,
  LINE_REMOVE: LINE_URL,
  LINE_MONITORING: `${LINE_URL}/Monitoring`,
  LINE_STATISTICS: `${STATISTICS_URL}/Line`,

  // Flow
  FLOW_SWITCH_VERSION: `${FLOW_URL}/Revert`,
  FLOW_VERSION_DIFF: `${FLOW_URL}/HistoryDiff`,
  FLOW_DUPLICATE: `${FLOW_URL}/Duplicate`,
  FLOW_REMOVE: FLOW_URL,
  FLOW_EDIT_BASIC: `${FLOW_URL}/Basic`,
  FLOW_CREATE: FLOW_URL,
  FLOW_BASIC: `${FLOW_URL}/Basic`,

  // Grammars
  GRAMMAR_BASIC: `${GRAMMAR_URL}/Basic`,
  GRAMMAR_CREATE: GRAMMAR_URL,
  GRAMMAR_DUPLICATE: `${GRAMMAR_URL}/Duplicate`,
  GRAMMAR_REMOVE: GRAMMAR_URL,

  // Permission groups
  PERMISSION_GROUP_CREATE: ROLES_URL,
  PERMISSION_GROUP_BASIC: `${ROLES_URL}/Basic`,
  PERMISSION_GROUP_REMOVE: ROLES_URL,
  PERMISSION_GROUP_PERMISSIONS: `${ROLES_URL}/Permission`,

  // Tools
  TTS_GENERATOR: `${TOOLS_URL}/TtsGenerator`,
  PACKAGE_INSTALL: `${TOOLS_URL}/InstallPackage`,
  PACKAGE_CREATE: `${TOOLS_URL}/CreateInstallPackage`,

  // Tenants
  TENANT_CREATE: TENANT_URL,
  TENANT_BASIC: `${TENANT_URL}/Basic`,
  TENANT_PARAMETERS: `${TENANT_URL}/Parameters`,
  TENANT_USER: `${TENANT_URL}/User`,
  TENANT_USER_BASIC: `${TENANT_URL}/UserBasic`,
  TENANT_USER_ROLES: `${TENANT_URL}/UserRoles`,
  TENANT_USER_NOTIFY_GROUPS: `${TENANT_URL}/NotificationGroups`,
  TENANT_USER_PASSWORD: `${TENANT_URL}/UserPassword`,
  TENANT_USER_API_SECRET: `${TENANT_URL}/UserApiSecret`,
  TENANT_STATISTICS: `${STATISTICS_URL}/Tenant`,
  TENANT_REMOVE: TENANT_URL,

  // Resources
  MODULES_LIST: `${RESOURCE_URL}/Modules`,
  FLOW_LIST: `${RESOURCE_URL}/Callscripts`,
  TENANT_LIST: `${RESOURCE_URL}/Tenants`,
  LANGUAGES_LIST: `${RESOURCE_URL}/Languages`,
  PERMISSIONS_GROUPS_LIST: `${RESOURCE_URL}/RulesGroup`,
  LANGUAGES_STRUCTURE: `${RESOURCE_URL}/SupportedLanguages`,
  NOTIFICATION_GROUPS: `${RESOURCE_URL}/NotificationGroups`,
  GRAMMAR_LIST: `${RESOURCE_URL}/Grammars`,
  COMPONENT_LIST: `${RESOURCE_URL}/Components`,

  // User profile
  USER_INFO: `${HOME_URL}/UserInfo`,
  USER_ACCOUNTS: `${SSO_URL}/api/resource/externalProviders`,
  USER_QR_CODE: `${ADMINISTRATION_URL}/Tenant/GetTwoFactorState`,
  USER_CHANGE_STATUS: `${ADMINISTRATION_URL}/Tenant/ChangeTwoFactorState`,

  // Temp
  CAMPAIGN_PREPARE: `${ADMINISTRATION_URL}/Campaign/Prepare`,
};

export default ApiEndpoints;
