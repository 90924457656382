import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider, VSkeletonLoader, VImg, VSwitch, VLayout } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import googleImg from "../../assets/img/google-logo.svg";
import appleImg from "../../assets/img/apple-logo-svgrepo-com.svg";
import ExternalLinks from "../../constants/externalLinks";

const UserProfileSecurity = Vue.extend({
  data: () => ({
    twoFactorEnabled: false,
    qrUrl: null,
    error: false
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_OVERVIEW_FETCH]),
    ...mapActions(Modules.INSTANCES, [Actions.USER_SECURITY_QR_CODE_FETCH]),
    ...mapActions(Modules.INSTANCES, [Actions.USER_SECURITY_STATUS_CHANGE]),

    fetchUserData(idUser: string) {
      this.USER_OVERVIEW_FETCH({ idUser });
    },

    async fetchQRCode(idUser: string) {
      const data = await this.USER_SECURITY_QR_CODE_FETCH({
        idUser
      });

      if(!data) return this.error = true;

      this.error = false;
      this.qrUrl = data.qrUrl;
    },

    async changeSecurityStatus(idUser: string, enable: boolean) {
      const data = await this.USER_SECURITY_STATUS_CHANGE({ idUser, enable });

      if(!data) return this.error = true;

      this.error = false;
      this.qrUrl = data.qrUrl;
    }
  },

  created() {
    this.INSPECTED_USER.basic ? null : this.fetchUserData(this.USER_INFO?.id);
    this.fetchQRCode(this.USER_INFO?.id);
  },

  render(): VNode {
    return <div>
        <VSwitch
          vModel={this.INSPECTED_USER.basic.content.twoFactorEnabled}
          onChange={(e: boolean) => {
              this.changeSecurityStatus(this.USER_INFO.id, e === true);
          }}
          value={this.INSPECTED_USER.basic.content.twoFactorEnabled}
          color="tertiary"
          >
            <template slot="label">
              <span
              class="ml-4"
              >{`Two-Step Verification: ${this.INSPECTED_USER.basic.content.twoFactorEnabled ? "On" : "Off"}`}</span>
            </template>
          </VSwitch>
          <VDivider />
          <div>
            {
              (this.IS_ACTION_FETCHING(Actions.USER_SECURITY_QR_CODE_FETCH)
              || this.IS_ACTION_FETCHING(Actions.USER_SECURITY_STATUS_CHANGE)) ? 
              <VSkeletonLoader type="text" class="mt-2" loading={true}/>
              :
              (this.qrUrl) ?
              <VLayout class="d-flex flex-row justify-space-around align-center pt-8 pl-6 pr-6" >
              <div class="caption" style="width: 50%;">
              <p>What to do after two-step verification confirmation?</p>

              <p>* First of all, is necessary to download Google authenticator app and
               scan QR code from which appears on the screen.</p>

              <p>* After finishing this setting you will always log in to the administration like usual.
              Second step will be entering a one-time code from your phone. This code is one-time and 
              is valid for 30 seconds.</p>
              </div>
              <div>
              <h2 class="black--text">Verification QR Code:</h2>
              <VImg
              class="mt-8 mb-8"
              max-height="500"
              max-width="300"
              src={this.qrUrl}
              >
              </VImg>
              </div>
              </VLayout> : (!this.error) ? "TWO-STEP VERIFICATION IS TURNED OFF - NO QR CODE AVAILABLE"
               : 
              "ERROR -  NO QR CODE AVAILABLE"
            }
          </div>
          <VDivider />
          <div class="pt-8">
            <div class="d-flex flex-column align-center">
            <h2>Download <u>Google Authenticator</u> mobile app:</h2>
            </div>
          <div
          class="d-flex flex-sm-row justify-space-around pt-8"
          >
            <a class="d-flex flex-column mr-16 align-center"
            href={ExternalLinks.GOOGLE_PLAY}
            target="_blank"
            ><VImg
            max-height="100"
            max-width="60"
            src={googleImg}
            alt="GooglePlay"
            ></VImg>
            <p>Google Play ©️</p>
            </a>
            <a
            class=" d-flex flex-column align-center"
            href={ExternalLinks.APPLE_STORE}
            target="_blank"
            ><VImg
            max-height="100"
            max-width="60"
            src={appleImg}
            alt="AppleStore"
            ></VImg>
            <p>App Store ©️</p>
            </a>
          </div>
          </div>
    </div>;
  }
});

export default UserProfileSecurity;