import Vue, { VNode } from "vue";
import { VDivider, VIcon, VList, VListItem } from "vuetify/lib";

const DialLogOverview = Vue.extend({
  props: {
    dialLogData: Object,
  },

  computed: {
    groupedVariables(): any {
      const flowVariables: any[] = [];
      const systemVariables: any[] = [];

      if (!this.dialLogData.variables) {
        return {};
      }

      Object.keys(this.dialLogData.variables)?.map((variable: string) => {
        const value = this.dialLogData.variables[variable];

        variable.startsWith("_")
          ? systemVariables.push({ name: variable, value })
          : flowVariables.push({ name: variable, value });
      });

      return { flowVariables, systemVariables };
    },
  },

  render(): VNode {
    const dialLogData: any = this.dialLogData;

    return (
      <div>
        <VList dense class="pt-0">
          <h3 class="text-h6 primary--text mb-2">Overview</h3>
          <VListItem>
            <VIcon small color="primary" class="mr-2">
              mdi-cog
            </VIcon>
            <strong class="primary--text">Guid</strong>: {dialLogData.guid}
          </VListItem>
          {/* <VListItem>
            <VIcon small color="primary" class="mr-2">
              mdi-clock
            </VIcon>
            <strong class="primary--text">Duration</strong>:{" "}
            {dialLogData.duration}s
          </VListItem>
          <VListItem>
            <VIcon small color="primary" class="mr-2">
              mdi-phone
            </VIcon>
            <strong class="primary--text">Result</strong>:{" "}
            {dialLogData.stateName}
          </VListItem> */}
        </VList>
        {dialLogData.variables &&
          Object.keys(dialLogData.variables).length > 0 && (
            <div>
              <VDivider />
              {this.groupedVariables.flowVariables.length > 0 && (
                <div>
                  <h3 class="text-h6 primary--text mb-1 mt-3">
                    Flow variables
                  </h3>
                  <VList dense>
                    {this.groupedVariables.flowVariables?.map(
                      ({ name, value }: any) => (
                        <VListItem>
                          <strong class="primary--text">{name}</strong>: {value}
                        </VListItem>
                      )
                    )}
                  </VList>
                  <VDivider />
                </div>
              )}
              {this.groupedVariables.systemVariables.length > 0 && (
                <div>
                  <h3 class="text-h6 primary--text mb-1 mt-3">
                    System variables
                  </h3>
                  <VList dense>
                    {this.groupedVariables.systemVariables?.map(
                      ({ name, value }: any) => (
                        <VListItem>
                          <strong class="primary--text">{name}</strong>: {value}
                        </VListItem>
                      )
                    )}
                  </VList>
                </div>
              )}
            </div>
          )}
      </div>
    );
  },
});

export default DialLogOverview;
