import Vue, { VNode } from "vue";
import { VCard, VCardText, VImg, VSpacer, VBtn, VIcon } from "vuetify/lib";
import googleLogoUrl from "@/assets/img/google-logo.svg";
import { SSO_URL } from "@/constants/apiEndpoints";

const accountsImages = {
  Google: googleLogoUrl,
};

const UserProfileAccountCard = Vue.extend({
  props: {
    authenticationScheme: String,
    displayName: String,
    connectUrl: String,
    disconnectUrl: String,
    isActive: Boolean,
  },

  methods: {
    redirectToConnectionAction(connectionString: string): void {
      const currentUrl = window.location.href;
      window.location.href = `${SSO_URL}${connectionString}${currentUrl}`;
    },
  },

  render(): VNode {
    return (
      <VCard class="mb-3 secondary">
        <VCardText>
          <div class="d-flex align-center">
            {accountsImages[this.authenticationScheme] && (
              <VImg
                src={accountsImages[this.authenticationScheme]}
                maxWidth={30}
                maxHeight={30}
              />
            )}
            <span class="text-subtitle-1 pl-3 primary--text">
              {this.displayName}
            </span>
            <VSpacer />{" "}
            <VBtn
              fab
              xSmall
              color={this.isActive ? "error" : "tertiary"}
              class="white--text"
              onClick={() =>
                this.redirectToConnectionAction(
                  this.connectUrl || this.disconnectUrl
                )
              }
            >
              <VIcon>{this.isActive ? "mdi-close" : "mdi-plus"}</VIcon>
            </VBtn>
          </div>
        </VCardText>
      </VCard>
    );
  },
});

export default UserProfileAccountCard;
