import { Actions, Getters, Modules, Mutations } from "@/models/store";
import Vue, { VNode } from "vue";
import { VAutocomplete, VBtn, VCheckbox, VDivider, VItemGroup } from "vuetify/lib";
import { mapActions, mapGetters, mapMutations } from "vuex";

const DynamicSelect = Vue.extend({
  props: {
    name: String,
    fetchUrl: String,
    fetchParams: Object,
    label: String,
    value: [String, Number, Array],
    isValueNumber: Boolean,
    options: Object,
    canSelectAll: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    isSelectedAll: false
  }),

  computed: {
    ...mapGetters(Modules.DROPDOWNS, [Getters.GET_DROPDOWN_DATA]),
    ...mapGetters(Modules.RESOURCES, [Getters.LANGUAGES]),
  },

  methods: {
    ...mapMutations(Modules.DROPDOWNS, [Mutations.SET_DROPDOWN_DATA]),
    ...mapActions(Modules.DROPDOWNS, [Actions.DROPDOWN_DATA_FETCH]),
    ...mapActions(Modules.RESOURCES, [Actions.LANGUAGES_FETCH]),

    selectAll(): void {
      if(this.isSelectedAll){
        this.$emit("input", this.GET_DROPDOWN_DATA(this.name).map(({ value }:any, index:number) => value));
      }else{
        this.$emit("input", []);
      }
    },

    async languagesFetch(): Promise<void> {

      const response = await this.LANGUAGES_FETCH();
    },

    languageList(): any[] {
      const languageNames: string[] = [];
      const languageList: any[] = [];

      this.LANGUAGES.forEach((language: any, index: number) => {
        if(!languageNames.includes(language.name)){
          languageNames.push(language.name);
          languageList.push({
            text: language.name,
            value: language.id
          })
        }
      })

      return languageList;
    }
  },

  created() {
    if(this.name === "LANGUAGES"){
      this.languagesFetch();
    }

    this.GET_DROPDOWN_DATA(this.name).length > 0 && !this.fetchParams
      ? null
      : this.DROPDOWN_DATA_FETCH({
          dropdown: this.name,
          url: this.fetchUrl,
          isValueNumber: this.isValueNumber,
          ...(this.fetchParams && { fetchParams: this.fetchParams }),
        });
  },

  render(): VNode {
    return (
    <VAutocomplete
      label={this.label}
      items={(this.name === "LANGUAGES") ? this.languageList() : this.GET_DROPDOWN_DATA(this.name)}
      onInput={(val: string | number) => this.$emit("input", val)}
      value={this.value}
      {...{ props: this.options }}
    >
    {
      (this.canSelectAll && 
      <template slot="prepend-item">
            <VCheckbox class="spacing-playground mr-4 ml-4 mb-4"
              color="info"
              onChange={(value: boolean) => {
                this.isSelectedAll = value;
                this.selectAll();
              }}
              hide-details
            >
              <template slot="label">
                <span class="spacing playground ml-5">Select All</span>
              </template>
            </VCheckbox>
            <VDivider />
      </template>
      )
    }
    </VAutocomplete>
    );
  },
});

export default DynamicSelect;
