import Vue, { VNode } from "vue";
import { VBtn, VIcon } from "vuetify/lib";

const RowActionButton = Vue.extend({
  props: {
    action: Function,
    icon: String,
    slotProps: Object,
    disabled: Boolean,
    primary: {
      type: Boolean,
      default: false,
    },
  },

  render(): VNode {
    return (
      <VBtn
        class={`${
          this.primary ? "tertiary white--text" : "secondary primary--text"
        } mr-3`}
        fab
        xSmall
        disabled={this.disabled}
        onClick={this.action ? this.action : () => null}
        {...this.slotProps}
      >
        <VIcon>{this.icon}</VIcon>
      </VBtn>
    );
  },
});

export default RowActionButton;
