const Dialogs = {
  // Flow
  FLOW_SWITCH_VERSION: "FLOW_SWITCH_VERSION",
  FLOW_CREATE: "FLOW_CREATE",
  FLOW_VERSION_DIFF: "FLOW_VERSION_DIFF",
  FLOW_DUPLICATE: "FLOW_DUPLICATE",
  FLOW_REMOVE: "FLOW_REMOVE",
  FLOW_EDIT_BASIC: "FLOW_EDIT_BASIC",
  FLOW_CSV_EXPORT: "FLOW_CSV_EXPORT",

  // Tenants
  TENANT_CREATE: "TENANT_CREATE",
  TENANT_NOTIFY_GROUP: "TENANT_NOTIFY_GROUP",
  TENANT_REMOVE: "TENANT_REMOVE",

  // Line
  LINE_EDIT_BASIC: "LINE_EDIT_BASIC",
  LINE_EDIT_CALLBOT_CONFIG: "LINE_EDIT_CALLBOT_CONFIG",
  LINE_ENABLE: "LINE_ENABLE",
  LINE_REMOVE: "LINE_REMOVE",
  LINE_MONITORING: "LINE_MONITORING",
  LINE_CSV_EXPORT: "LINE_CSV_EXPORT",
  LINE_CREATE: "LINE_CREATE",
  LINE_CHANGE_MODULE: "LINE_CHANGE_MODULE",

  // Module
  MODULE_EDIT_BASIC: "MODULE_EDIT_BASIC",
  MODULE_CALLBOT_CONFIGURATION: "MODULE_CALLBOT_CONFIGURATION",
  MODULE_CSV_EXPORT: "MODULE_CSV_EXPORT",
  MODULE_REMOVE: "MODULE_REMOVE",
  MODULE_SET_DEFAULT_LINE: "MODULE_SET_DEFAULT_LINE",

  // Grammar
  GRAMMAR_CREATE: "GRAMMAR_CREATE",
  GRAMMAR_DUPLICATE: "GRAMMAR_DUPLICATE",
  GRAMMAR_REMOVE: "GRAMMAR_REMOVE",
  GRAMMAR_EDIT_BASIC: "GRAMMAR_EDIT_BASIC",
  GRAMMAR_EDIT_CONTENT: "GRAMMAR_EDIT_CONTENT",

  // User
  USER_CREATE: "USER_CREATE",
  USER_ROLES: "USER_ROLES",
  USER_EDIT_BASIC: "USER_EDIT_BASIC",
  USER_REMOVE: "USER_REMOVE",
  USER_API_SECRET_REMOVE: "USER_API_SECRET_REMOVE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",

  // Tenant
  TENANT_EDIT_BASIC: "TENANT_EDIT_BASIC",
  TENANT_EDIT_PARAMS: "TENANT_EDIT_PARAMS",

  // Permission groups
  PERMISSION_GROUP_CREATE: "PERMISSION_GROUP_CREATE",
  PERMISSION_GROUP_REMOVE: "PERMISSION_GROUP_REMOVE",
  PERMISSION_GROUP_EDIT_BASIC: "PERMISSION_GROUP_EDIT_BASIC",
  PERMISSION_GROUP_PERMISSIONS_EDIT: "PERMISSION_GROUP_PERMISSIONS_EDIT",

  // User profile
  USER_CONNECTED_ACCOUNTS: "USER_CONNECTED_ACCOUNTS",

  // Campaign
  CAMPAIGN_CREATE: "CAMPAIGN_CREATE",

  // Global
  DIAL_LOG_DETAIL: "DIAL_LOG_DETAIL",
  PACKAGE_INSTALL: "PACKAGE_INSTALL",
  DIAL_LOG_AUDIO: "DIAL_LOG_AUDIO",
};

export default Dialogs;
