import { GetterTree } from "vuex";
import { Getters } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";

const getters: GetterTree<InstancesState, []> = {
  [Getters.INSPECTED_MODULE]: (state) => state.inspectedModule,

  [Getters.INSPECTED_LINE]: (state) => state.inspectedLine,

  [Getters.INSPECTED_FLOW]: (state) => state.inspectedFlow,

  [Getters.INSPECTED_GRAMMAR]: (state) => state.inspectedGrammar,

  [Getters.INSPECTED_TENANT]: (state) => state.inspectedTenant,

  [Getters.INSPECTED_USER]: (state) => state.inspectedUser,

  [Getters.INSPECTED_PERMISSION_GROUP]: (state) =>
    state.inspectedPermissionGroup,
};

export default getters;
