import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VCol, VRow, VSkeletonLoader } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import PermissionGroupPermissionsEditDialog from "../PermissionGroupPermissionsEditDialog";
import PermissionsAccordion from "../PermissionsAccordion";
import SectionToolbar from "../SectionToolbar";

const PermissionGroupPermissions = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_PERMISSION_GROUP]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.PERMISSIONS_WITH_GROUPS_FETCH]),
  },

  created() {
    const idRole = this.$route.params.idRole;
    this.PERMISSIONS_WITH_GROUPS_FETCH({ idRole });
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="Permissions"
          action={{
            icon: "mdi-pencil",
            action: () =>
              toggleDialog(Dialogs.PERMISSION_GROUP_PERMISSIONS_EDIT, {}),
          }}
        />
        <VRow>
          <VCol col={12} md={8}>
            {this.IS_ACTION_FETCHING(Actions.PERMISSIONS_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.PERMISSIONS_GROUPS_FETCH) ? (
              <VSkeletonLoader type="list-item@5" />
            ) : (
              <PermissionsAccordion
                content={this.INSPECTED_PERMISSION_GROUP.permissions?.content}
              />
            )}
          </VCol>
        </VRow>
        <PermissionGroupPermissionsEditDialog />
      </div>
    );
  },
});

export default PermissionGroupPermissions;
