import Vue, { VNode, PropType } from "vue";
import { VCol, VRow } from "vuetify/lib";
import DataTextItem from "@/models/DataTextItem";

const DataTextOverview = Vue.extend({
  props: {
    items: Array as PropType<DataTextItem[]>,
  },

  render(): VNode {
    return (
      <div>
        {this.items.length === 0 ? (
          <span class="text-subtitle-1">No data avalible</span>
        ) : (
          <VRow class="mt-1 mb-1">
            {this.items.map(({ title, value }: DataTextItem) => (
              <VCol cols={12} sm={6}>
                <div>
                  <h3 class="primary--text mb-1" style="font-size: 0.95rem">
                    {title}:
                  </h3>
                  <div style="color: rgba(0, 0, 0, 0.87); font-size: 0.95rem;">
                    {value}
                  </div>
                </div>
              </VCol>
            ))}
          </VRow>
        )}
      </div>
    );
  },
});

export default DataTextOverview;
