import Vue, { VNode } from "vue";
import CountryFlag from "vue-country-flag";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import getCountryCode from "@/helpers/getCountryCode";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import useGrammarActions from "@/hooks/useGrammarActions";
import GrammarCreateDialog from "@/components/GrammarCreateDialog";
import GrammarDuplicateDialog from "@/components/GrammarDuplicateDialog";
import GrammarRemoveDialog from "@/components/GrammarRemoveDialog";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const GrammarsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.GRAMMARS_DATAGRID_FETCH]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Grammars",
      isHeaderOutside: true,
      buttons: [
        {
          role: "primary",
          title: "New grammar",
          icon: "mdi-plus",
          action: () => toggleDialog(Dialogs.GRAMMAR_CREATE, {}),
          isAllowed: hasUserPermissions(Namespaces.GRAMMAR, [
            Permissions.CREATE,
          ]),
        },
      ],
      rowActions: (row) => ({
        primary: {
          action: ({ id }) =>
            this.$router.push({
              name: Routes.GRAMMARS_DETAIL_BASIC,
              params: { idGrammar: id },
            }),
          icon: "mdi-cog",
          title: "Settings",
          isAllowed: hasUserPermissions(Namespaces.GRAMMAR, [Permissions.VIEW]),
        },
        secondary: useGrammarActions(row),
      }),
      columnModificators: {
        "item.language": ({ item }) => (
          <div class="d-flex align-center">
            <CountryFlag size="small" country={getCountryCode(item.language)} />
            <span class="pl-1">{item.language}</span>
          </div>
        ),
        "item.lastChange": ({ item }) => formatDate(item.lastChange),
      },
      headers: [
        { text: "Name", value: "name" },
        { text: "Language", value: "language" },
        { text: "Type", value: "typeName" },
        { text: "Last change", value: "lastChange" },
        { text: "Author", value: "owner" },
      ],
      filters: [{ name: "name" }, { name: "language" }],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.GRAMMARS_DATAGRID_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.GRAMMARS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.GRAMMARS_DATAGRID_FETCH)}
        />

        <GrammarCreateDialog />
        <GrammarDuplicateDialog />
        <GrammarRemoveDialog />
      </div>
    );
  },
});

export default GrammarsDataGrid;
