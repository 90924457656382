import Dialogs from "@/constants/dialogs";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import UserEditOverviewForm from "../UserEditOverviewForm";
import UserProfileConnectedAccounts from "../UserProfileConnectedAccounts";

const UserProfileOverview = Vue.extend({
  data: () => ({
    basic: {
      title: "Overview",
      modificators: {
        created: (val: string) => formatDate(val),
        lastLogin: (val: string) => formatDate(val),
      },
      columns: [
        { name: "firstname", title: "Full name" },
        { name: "username", title: "User name" },
        { name: "tenant", title: "Tenant" },
        { name: "email", title: "Email" },
        { name: "created", title: "Created at" },
        { name: "lastLogin", title: "Last login at" },
      ],
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
    ...mapGetters(Modules.USER, [Getters.USER_INFO, Getters.USER_ACCOUNTS]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_OVERVIEW_FETCH]),

    fetchUserData(idUser: string): void {
      this.USER_OVERVIEW_FETCH({ idUser });
    },
  },

  created() {
    const idUser = this.USER_INFO?.id;
    idUser ? this.fetchUserData(idUser) : null;
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_USER?.basic}
          editDialog={Dialogs.USER_EDIT_BASIC}
          action={Actions.USER_OVERVIEW_EDIT}
          scopedSlots={{
            form: () => (
              <UserEditOverviewForm ref="form" idUser={this.USER_INFO?.id} />
            ),
          }}
          isDataFetching={
            this.IS_ACTION_FETCHING(Actions.USER_OVERVIEW_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.FETCH_USER_INFO)
          }
        />
        <VDivider class="mb-5" />
        <UserProfileConnectedAccounts />
      </div>
    );
  },
});

export default UserProfileOverview;
