import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import { Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapGetters } from "vuex";
import ChangePasswordDialog from "../ChangePasswordDialog";
import DetailWithTabs from "../DetailWithTabs";

const UserProfileDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title="Profile settings"
          defaultReturnRoute={Routes.FLOW_OVERVIEW}
          actions={[
            {
              title: "Change password",
              action: () =>
                toggleDialog(Dialogs.CHANGE_PASSWORD, {
                  idUser: this.USER_INFO.id,
                }),
              icon: "mdi-sync",
              isAllowed: hasUserPermissions(Namespaces.TENANT, [
                Permissions.EDIT_ACCOUNT,
              ]),
            },
          ]}
          tabs={[
            {
              title: "General",
              path: Routes.USER_PROFILE_BASIC,
            },
            {
              title: "Permissions",
              path: Routes.USER_DETAIL_PERMISSIONS,
            },
            {
              title: "Security",
              path: Routes.USER_DETAIL_SECURITY,
            },
          ]}
        />
        <ChangePasswordDialog />
      </div>
    );
  },
});

export default UserProfileDetail;
