import ActionHandler from "@/models/actions/ActionHandler";
import Vue, { PropType, VNode } from "vue";
import { VBtn, VIcon, VSpacer, VToolbar, VToolbarTitle } from "vuetify/lib";

const SectionToolbar = Vue.extend({
  props: {
    title: String,
    action: Object as PropType<ActionHandler>,
  },

  render(): VNode {
    return (
      <VToolbar flat class="mb-2">
        <VToolbarTitle
          class="primary--text font-weight-bold text-h5"
          inset
          vertical
        >
          {this.title}
        </VToolbarTitle>
        <VSpacer />
        {this.action && (
          <VBtn
            color="tertiary"
            onClick={this.action.action}
            class="ml-2 secondary--text"
            rounded
            fab={!this.action.title}
            small={!this.action.title}
          >
            <VIcon left={!!this.action.title}>{this.action.icon}</VIcon>
            {this.action.title}
          </VBtn>
        )}
      </VToolbar>
    );
  },
});

export default SectionToolbar;
