const Dropdowns = {
  MODULES: "MODULES",
  FLOW: "FLOW",
  TENANTS: "TENANTS",
  LANGUAGES: "LANGUAGES",
  NOTIFY_GROUPS: "NOTIFY_GROUPS",
  GRAMMARS: "GRAMMARS",
  COMPONENTS: "COMPONENTS",
};

export default Dropdowns;
