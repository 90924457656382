import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import { VIcon } from "vuetify/lib";
import DataGrid from "../DataGrid";
import { Getters, Actions, Modules } from "@/models/store";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import DataGrids from "@/constants/dataGrids";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import Dialogs from "@/constants/dialogs";
import CsvExportDialog from "@/components/CsvExportDialog";
import FlowCreateDialog from "@/components/FlowCreateDialog";
import FlowDuplicateDialog from "@/components/FlowDuplicateDialog";
import FlowRemoveDialog from "@/components/FlowRemoveDialog";
import ApiEndpoints from "@/constants/apiEndpoints";
import { FLOW_TYPE_ICONS } from "@/constants/valueIcons";

const CallScriptsDataGrid = Vue.extend({
  props: {
    extendedConfig: Object,
    fetchProps: Object,
    showType: {
      type: Boolean,
      default: true,
    },
    isFavoriteEnabled: {
      type: Boolean,
      default: true,
    },
  },

  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [
      Getters.GET_DATAGRID_DATA,
      Getters.GET_DATAGRID_FILTER,
    ]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    isFavoriteActive(): boolean {
      return !!this.GET_DATAGRID_FILTER(DataGrids.CALL_SCRIPTS)?.isFavorite;
    },
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [
      Actions.CALLSCRIPTS_DATAGRID_FETCH,
      Actions.SET_FAVORITE_ITEM,
    ]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      isHeaderOutside: true,
      columnModificators: {
        "item.type": ({ item }) => (
          <span>
            <VIcon small class="mr-2" color="primary">
              {`mdi-${FLOW_TYPE_ICONS[item.type].icon}`}
            </VIcon>
            {FLOW_TYPE_ICONS[item.type].name}
          </span>
        ),
        "item.lastChange": ({ item }) => formatDate(item.lastChange),
        "item.name": ({ item }) => (
          <span class="d-flex align-center">
            {this.isFavoriteEnabled && (
              <VIcon
                small
                class="mr-1"
                color={item.isFavorite ? "tertiary" : ""}
                onClick={() =>
                  this.SET_FAVORITE_ITEM({
                    id: item.id,
                    dataset: "callscripts",
                    dataGrid: DataGrids.CALL_SCRIPTS,
                    actionId: Actions.CALLSCRIPTS_DATAGRID_FETCH,
                    isFavorite: item.isFavorite,
                  })
                }
              >
                {item.isFavorite ? "mdi-star" : "mdi-star-outline"}
              </VIcon>
            )}

            {item.name}
          </span>
        ),
      },
      headers: [
        { text: "Name", value: "name" },
        { ...(this.showType && { text: "Type", value: "type" }) },
        { text: "Last change", value: "lastChange" },
        { text: "Author", value: "author" },
      ],
      filters: [
        { name: "name" },
        {
          name: "type",
          values: [
            { value: 0, text: "Voice" },
            { value: 1, text: "Chat" },
            { value: 2, text: "RPA" },
          ],
        },
        { name: "author" },
        { name: "lastChange", withDatePicker: true },
      ],
    };

    this.dataGridConfig = { ...dataGridConfig, ...this.extendedConfig };
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.CALLSCRIPTS_DATAGRID_FETCH}
          fetchProps={this.fetchProps}
          data={this.GET_DATAGRID_DATA(DataGrids.CALL_SCRIPTS)}
          configuration={this.dataGridConfig}
          isFavoriteActive={this.isFavoriteActive}
          isFetching={
            this.IS_ACTION_FETCHING(Actions.CALLSCRIPTS_DATAGRID_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.SET_FAVORITE_ITEM)
          }
        />
        <CsvExportDialog
          name={Dialogs.FLOW_CSV_EXPORT}
          apiEndpoint={ApiEndpoints.CSV_REPORTS_FLOW}
          prefix="flow"
        />
        <FlowCreateDialog />
        <FlowDuplicateDialog />
        <FlowRemoveDialog />
      </div>
    );
  },
});

export default CallScriptsDataGrid;
