import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { isRequired } from "@/helpers/validations";
import { Actions } from "@/models/store";
import Vue, { VNode } from "vue";
import { VCombobox, VForm, VSelect, VTextField } from "vuetify/lib";
import Dialog from "../Dialog";

const NOTIFY_TYPES = [{ text: "Email", value: 0 }];

const TenantNotifyGroupDialog = Vue.extend({
  data: () => ({
    fields: {
      name: "",
      type: 0,
      contacts: [],
    },
    isFormValid: true,
  }),

  props: {},

  methods: {
    async handleSubmit() {
      const { contacts, ...fields } = this.fields;

      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.TENANT_USER_NOTIFY_GROUPS,
        values: [
          {
            ...fields,
            options: [JSON.stringify({ contacts })],
          },
        ],
        params: { idTenant: this.$route.params.idTenant },
        actionId: Actions.USER_CREATE_NOTIFY_GROUP,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.TENANT_NOTIFY_GROUP, {});
        pushNotify(Notify.SUCCESS, "Notification group added successfully");
      }
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.TENANT_NOTIFY_GROUP}
        title="Notification group"
        actions={[
          {
            title: "Add",
            icon: "mdi-plus",
            action: () => this.handleSubmit(),
            id: Actions.USER_CREATE_NOTIFY_GROUP,
          },
        ]}
      >
        <VForm ref="form" vModel={this.isFormValid}>
          <VTextField
            vModel={this.fields.name}
            label="Name"
            rules={[isRequired]}
          />
          <VSelect
            vModel={this.fields.type}
            label="Type"
            items={NOTIFY_TYPES}
          />
          <VCombobox
            vModel={this.fields.contacts}
            label="Contacts"
            smallChips
            deletableChips
            multiple
            rules={[isRequired]}
          />
        </VForm>
      </Dialog>
    );
  },
});

export default TenantNotifyGroupDialog;
