import Routes from "@/constants/routes";
import Vue, { VNode } from "vue";
import { VAlert, VBtn, VIcon } from "vuetify/lib";

const NotAuthorized = Vue.extend({
  render(): VNode {
    return (
      <div class="not-found">
        <h2 class="text-h4 font-weight-bold primary--text mb-2">
          403 - Not authorized
        </h2>
        <VAlert outlined type="error" class="mt-5 d-inline-block">
          You don't have a permission to display this page. Click{" "}
          <router-link
            class="tertiary--text"
            to={{ name: Routes.FLOW_OVERVIEW }}
          >
            here
          </router-link>{" "}
          to get back to homepage.
        </VAlert>
      </div>
    );
  },
});

export default NotAuthorized;
