import generateErrorMsg from "@/helpers/generateErrorMsg";
import ActionHandler from "@/models/actions/ActionHandler";
import { Getters, Modules, Mutations } from "@/models/store";
import Vue, { PropType, VNode } from "vue";
import {
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
  VBtn,
  VIcon,
} from "vuetify/lib";
import { mapGetters, mapMutations } from "vuex";

const Dialog = Vue.extend({
  props: {
    id: String,
    title: String,
    actions: Array as PropType<ActionHandler[]>,
    width: [String, Number],
    toggleHandler: Function,
    noCloseIcon: Boolean,
    closeOnEsc: {
      type: Boolean,
      default: true
    },
    persistent: {
      type: Boolean,
      default: true
    },
  },

  data: () => ({
    isDialogActive: false,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.IS_DIALOG_OPEN]),
    ...mapGetters(Modules.API_CALLS, [
      Getters.IS_ACTION_FETCHING,
      Getters.IS_ACTION_ERROR,
    ]),

    isDialogOpen: {
      get(): boolean {
        return this.IS_DIALOG_OPEN(this.id);
      },
      set() {
        this.TOGGLE_DIALOG({ name: this.id })
      }
    },

    isLoading(): boolean {
      return !!this.actions?.find(({ id }) => this.IS_ACTION_FETCHING(id));
    },

    error(): string {
      const actionWithError = this.actions?.find(({ id }) =>
        this.IS_ACTION_ERROR(id)
      );

      if (!actionWithError) {
        return "";
      }

      const actionId: any = actionWithError.id;
      return generateErrorMsg(actionId);
    },
  },

  methods: {
    ...mapMutations(Modules.DIALOGS, [Mutations.TOGGLE_DIALOG]),
  },

  watch: {
    isDialogOpen(value) {
      this.toggleHandler ? this.toggleHandler(value) : null;

      value
        ? (this.isDialogActive = value)
        : setTimeout(() => (this.isDialogActive = value), 300);
    },
  },

  created() {
    this.isDialogActive = this.isDialogOpen;
  },

  render(): VNode {
    const { title, actions, id } = this;
    const scopedSlots: any = this.$scopedSlots;

    return (
      <div>
        {this.isDialogActive && (
          <VDialog
            vModel={this.isDialogOpen}
            maxWidth={this.width || "500"}
            persistent={this.persistent}
            onKeydown={(e: KeyboardEvent) =>
              e.key === "Escape" && this.closeOnEsc
                ? this.TOGGLE_DIALOG({ name: id })
                : null
            }
          >
            <VCard loading={this.isLoading}>
              {!this.noCloseIcon && (
                <VBtn
                  onClick={() => this.TOGGLE_DIALOG({ name: id })}
                  small
                  fab
                  plain
                  style="position: absolute; right: 0; top: 0;"
                >
                  <VIcon>mdi-close</VIcon>
                </VBtn>
              )}

              {title && <VCardTitle>{title}</VCardTitle>}
              {scopedSlots.beforeContent && scopedSlots.beforeContent()}
              <VCardText>
                {this.$slots.default}{" "}
                {this.error && (
                  <div class="error--text mt-4 d-flex align-center">
                    <VIcon class="pr-1 error--text">mdi-alert-octagon</VIcon>
                    {this.error}
                  </div>
                )}
              </VCardText>
              <VCardActions class="justify-end">
                <VBtn
                  disabled={this.isLoading}
                  plain
                  onClick={() => this.TOGGLE_DIALOG({ name: id })}
                >
                  Cancel
                </VBtn>
                {actions?.map(({ title, icon, action }) => (
                  <VBtn
                    color="tertiary white--text"
                    onClick={action}
                    disabled={this.isLoading}
                  >
                    {icon && <VIcon left>{icon}</VIcon>}
                    {title}
                  </VBtn>
                ))}
              </VCardActions>
            </VCard>
          </VDialog>
        )}
      </div>
    );
  },
});

export default Dialog;
