import { ActionTree } from "vuex";
import InstancesState from "@/models/store/InstancesState";
import instanceActions from "./instanceActions";
import userActions from "./userActions";
import tenantActions from "./tenantActions";
import moduleActions from "./moduleActions";
import grammarActions from "./grammarActions";
import lineActions from "./lineActions";
import flowActions from "./flowActions";
import permissionGroupActions from "./permissionGroupActions";

const actions: ActionTree<InstancesState, []> = {
  ...instanceActions,
  ...lineActions,
  ...grammarActions,
  ...moduleActions,
  ...flowActions,
  ...tenantActions,
  ...userActions,
  ...permissionGroupActions,
};

export default actions;
