enum Actions {
  // Datagrids
  DATAGRID_FETCH = "DATAGRID_FETCH",
  DATAGRID_FETCH_WITH_CURRENT_PARAMS = "FETCH_DATAGRID_WITH_CURRENT_PARAMS",
  FILTER_FAVORITE_ITEMS = "FILTER_FAVORITE_ITEMS",
  SET_FAVORITE_ITEM = "SET_FAVORITE_ITEM",

  MODULES_DATAGRID_FETCH = "MODULES_DATAGRID_FETCH",
  LINES_DATAGRID_FETCH = "LINES_DATAGRID_FETCH",
  DIAL_LOGS_DATAGRID_FETCH = "DIAL_LOGS_DATAGRID_FETCH",
  CALLSCRIPTS_DATAGRID_FETCH = "CALLSCRIPTS_DATAGRID_FETCH",
  FLOW_VERSION_HISTORY_DATAGRID_FETCH = "FLOW_VERSION_HISTORY_DATAGRID_FETCH",
  CAMPAIGNS_DATAGRID_FETCH = "CAMPAIGNS_DATAGRID_FETCH",
  GRAMMARS_DATAGRID_FETCH = "GRAMMARS_DATAGRID_FETCH",
  USERS_DATAGRID_FETCH = "USERS_DATAGRID_FETCH",
  TENANTS_DATAGRID_FETCH = "TENANTS_DATAGRID_FETCH",
  AUDIT_DATAGRID_FETCH = "AUDIT_DATAGRID_FETCH",
  PERMISSION_GROUPS_DATAGRID_FETCH = "PERMISSION_GROUPS_DATAGRID_FETCH",

  // User
  FETCH_API_KEY = "FETCH_API_KEY",
  FETCH_USER_INFO = "FETCH_USER_INFO",
  USER_LOG_IN = "USER_LOG_IN",
  USER_ACCOUNTS_FETCH = "USER_ACCOUNTS_FETCH",

  // Instances
  FLOW_SWITCH_VERSION = "FLOW_SWITCH_VERSION",
  FLOW_VERSION_DIFF = "FLOW_VERSION_DIFF",
  FLOW_DUPLICATE = "FLOW_DUPLICATE",
  FLOW_REMOVE = "FLOW_REMOVE",
  FLOW_OVERVIEW_EDIT = "FLOW_OVERVIEW_EDIT",
  FLOW_OVERVIEW_FETCH = "FLOW_OVERVIEW_FETCH",
  FLOW_CREATE = "FLOW_CREATE",

  MODULE_OVERVIEW_FETCH = "MODULE_OVERVIEW_FETCH",
  MODULE_CALLBOT_CONFIG_FETCH = "MODULE_CALLBOT_CONFIG_FETCH",
  MODULE_OVERVIEW_EDIT = "MODULE_OVERVIEW_EDIT",
  MODULE_CALLBOT_SETTINGS_EDIT = "MODULE_CALLBOT_SETTINGS_EDIT",
  MODULE_REMOVE = "MODULE_REMOVE",
  MODULE_SET_DEFAULT_LINE = "MODULE_SET_DEFAULT_LINE",

  USER_OVERVIEW_FETCH = "USER_OVERVIEW_FETCH",
  USER_ROLES_FETCH = "USER_ROLES_FETCH",
  USER_OVERVIEW_EDIT = "USER_OVERVIEW_EDIT",
  USER_CREATE_NOTIFY_GROUP = "USER_CREATE_NOTIFY_GROUP",
  USER_REMOVE = "USER_REMOVE",
  USER_PASSWORD_CHANGE = "USER_PASSWORD_CHANGE",
  USER_API_SECRET_FETCH = "USER_API_SECRET_FETCH",
  USER_API_SECRET_GENERATE = "USER_API_SECRET_GENERATE",
  USER_API_SECRET_REMOVE = "USER_API_SECRET_REMOVE",
  USER_SECURITY_QR_CODE_FETCH = "USER_SECURITY_QR_CODE_FETCH",
  USER_SECURITY_STATUS_CHANGE = "USER_SECURITY_STATUS_CHANGE",

  PERMISSION_GROUP_CREATE = "PERMISSION_GROUP_CREATE",
  PERMISSION_GROUP_OVERVIEW_FETCH = "PERMISSION_GROUP_OVERVIEW_FETCH",
  PERMISSION_GROUP_REMOVE = "PERMISSION_GROUP_REMOVE",
  PERMISSIONS_GROUPS_FETCH = "PERMISSIONS_GROUPS_FETCH",
  PERMISSIONS_FETCH = "PERMISSIONS_FETCH",
  PERMISSIONS_WITH_GROUPS_FETCH = "PERMISSIONS_WITH_GROUPS_FETCH",
  PERMISSION_GROUP_OVERVIEW_EDIT = "PERMISSION_GROUP_OVERVIEW_EDIT",
  PERMISSION_GROUP_PERMISSIONS_EDIT = "PERMISSION_GROUP_PERMISSIONS_EDIT",

  LINE_OVERVIEW_FETCH = "LINE_OVERVIEW_FETCH",
  LINE_OVERVIEW_EDIT = "LINE_OVERVIEW_EDIT",
  LINE_CALLBOT_CONFIG_FETCH = "LINE_CALLBOT_CONFIG_FETCH",
  LINE_CALLBOT_CONFIG_EDIT = "LINE_CALLBOT_CONFIG_EDIT",
  LINE_SET_MODULE = "LINE_SET_MODULE",
  LINE_TOGGLE = "LINE_TOGGLE",
  LINE_CREATE = "LINE_CREATE",
  LINE_REMOVE = "LINE_REMOVE",
  LINE_MONITORING_EDIT = "LINE_MONITORING_EDIT",
  LINE_MONITORING_FETCH = "LINE_MONITORING_FETCH",
  LINE_STATISTICS_FETCH = "LINE_STATISTICS_FETCH",

  GRAMMAR_OVERVIEW_FETCH = "GRAMMAR_OVERVIEW_FETCH",
  GRAMMAR_OVERVIEW_EDIT = "GRAMMAR_OVERVIEW_EDIT",
  GRAMMAR_CREATE = "GRAMMAR_CREATE",
  GRAMMAR_DUPLICATE = "GRAMMAR_DUPLICATE",
  GRAMMAR_REMOVE = "GRAMMAR_REMOVE",

  TENANT_OVERVIEW_FETCH = "TENANT_OVERVIEW_FETCH",
  TENANT_PARAMS_FETCH = "TENANT_PARAMS_FETCH",
  TENANT_CREATE = "TENANT_CREATE",
  TENANT_USER_CREATE = "TENANT_USER_CREATE",
  TENANT_FETCH_NOTIFICATION_GROUPS = "TENANT_FETCH_NOTIFICATION_GROUPS",
  TENANT_REMOVE = "TENANT_REMOVE",
  TENANT_OVERVIEW_EDIT = "TENANT_OVERVIEW_EDIT",
  TENANT_PARAMS_EDIT = "TENANT_PARAMS_EDIT",
  TENANT_STATISTICS_FETCH = "TENANT_STATISTICS_FETCH",
  TENANT_CSV_STATISTICS_FETCH = "TENANT_CSV_STATISTICS_FETCH",
  GENERATE_TENANT_CSV_STATISTICS_FETCH = "GENERATE_TENANT_CSV_STATISTICS_FETCH",

  SECTION_DETAIL_FETCH = "SECTION_DETAIL_FETCH",
  INSTANCE_DUPLICATE = "INSTANCE_DUPLICATE",
  INSTANCE_REMOVE = "INSTANCE_REMOVE",

  INSTANCE_FETCH_CSV_STATISTICS = "INSTANCE_FETCH_CSV_STATISTICS",
  AUDIO_RECORD_FETCH = "AUDIO_RECORD_FETCH",
  GENERATE_CSV_REPORTS = "GENERATE_CSV_REPORTS",
  DIAL_LOG_FETCH = "DIAL_LOG_FETCH",

  // Tools
  TTS_GENERATOR = "TTS_GENERATOR",
  PACKAGE_CREATE = "PACKAGE_CREATE",
  PACKAGE_INSTALL = "PACKAGE_INSTALL",

  // Notification
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",

  // Dropdowns
  DROPDOWN_DATA_FETCH = "DROPDOWN_DATA_FETCH",

  // Resources
  LANGUAGES_FETCH = "LANGUAGES_FETCH",

  // Temp
  CAMPAIGN_PREPARE = "CAMPAIGN_PREPARE",
}

export default Actions;
