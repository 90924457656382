import Dialogs from "@/constants/dialogs";
import ExternalLinks from "@/constants/externalLinks";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import useFlowActions from "@/hooks/useFlowActions";
import Vue, { VNode } from "vue";
import CallScriptsDataGrid from "../CallScriptsDataGrid";

const ComponentsDataGrid = Vue.extend({
  data: () => ({
    extendedConfig: {
      title: "Components",
      buttons: [
        {
          title: "New component",
          icon: "mdi-plus",
          isAllowed: hasUserPermissions(Namespaces.COMPONENT, [
            Permissions.CREATE,
          ]),
          action: () =>
            toggleDialog(Dialogs.FLOW_CREATE, { isComponent: true }),
        },
      ],
      rowActions: (row: any) => ({
        primary: {
          icon: "mdi-file-tree",
          title: "Flow design",
          isAllowed: hasUserPermissions(Namespaces.COMPONENT, [
            Permissions.VIEW,
          ]),
          action: () =>
            window
              .open(`${ExternalLinks.DESIGNER}?scenarioId=${row.id}`, "_self")
              ?.focus(),
        },
        secondary: useFlowActions({ ...row, isComponent: true }),
      }),
    },
  }),

  render(): VNode {
    return (
      <CallScriptsDataGrid
        extendedConfig={this.extendedConfig}
        showType={false}
        fetchProps={{ isComponent: true }}
        isFavoriteEnabled={false}
      />
    );
  },
});

export default ComponentsDataGrid;
