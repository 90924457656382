import InstancesState from "@/models/store/InstancesState";

const state: InstancesState = {
  inspectedModule: {
    basic: null,
    callbotSettings: null,
  },

  inspectedLine: {
    basic: null,
    callbotSettings: null,
    statistics: null,
    monitoring: null,
  },

  inspectedFlow: {
    basic: null,
  },

  inspectedGrammar: {
    basic: null,
  },

  inspectedTenant: {
    basic: null,
    notifyGroups: null,
    statistics: null,
    parameters: null,
  },

  inspectedUser: {
    basic: null,
    roles: null,
    apiSecret: "",
  },

  inspectedPermissionGroup: {
    basic: null,
    permissions: null,
  },
};

export default state;
