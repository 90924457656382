const formatNumber = (number: number) => (number > 9 ? number : `0${number}`);

export const formatDateForDatePicker = (date: Date): string => {
  const dateFormat = new Date(date);

  const year = dateFormat.getFullYear();
  const month = formatNumber(dateFormat.getMonth() + 1);
  const day = formatNumber(dateFormat.getDate());

  return `${year}-${month}-${day}`;
};

export const getFirstDayCurrentMonth = (): Date => {
  const date = new Date();

  return new Date(date.getFullYear(), date.getMonth(), 1);
};

export const getLastDayCurrentMonth = (): Date => {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();

  return new Date(year, month + 1, 0);
};

export const formatDate = (
  date: string,
  options = {},
  local = "cs-CZ"
): string =>
  new Date(date).toLocaleString(local, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    ...options,
  });

export const formatTime = (time: number): string => {
  let seconds = 0;
  let minutes = 0;

  if (time < 60) {
    seconds = time;
  } else {
    minutes = Math.trunc(time / 60);
    seconds = time % 60;
  }

  return `${minutes > 0 ? `${minutes}min` : ""} ${seconds}s`;
};
