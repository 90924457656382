import Dialogs from "@/constants/dialogs";
import { Namespaces, Permissions } from "@/constants/permissions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import ActionHandler from "@/models/actions/ActionHandler";
import UserActionsParams from "@/models/actions/UserActionsParams";

const useUserActions = ({
  id,
  username,
}: UserActionsParams): ActionHandler[] => [
  {
    title: "Change password",
    action: () =>
      toggleDialog(Dialogs.CHANGE_PASSWORD, {
        idUser: id,
      }),
    icon: "mdi-sync",
    isAllowed: hasUserPermissions(Namespaces.TENANT, [
      Permissions.EDIT_ACCOUNT_ALL,
    ]),
  },
  {
    icon: "mdi-delete",
    title: "Remove",
    action: () => toggleDialog(Dialogs.USER_REMOVE, { id, username }),
    isAllowed: hasUserPermissions(Namespaces.TENANT, [
      Permissions.DELETE_ACCOUNT,
    ]),
  },
];

export default useUserActions;
