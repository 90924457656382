import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import TenantCreateDialog from "../TenantCreateDialog";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import { VIcon } from "vuetify/lib";
import TenantRemoveDialog from "../TenantRemoveDialog";
import useTenantActions from "@/hooks/useTenantActions";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import { Namespaces, Permissions } from "@/constants/permissions";

const TenantsDataGrid = Vue.extend({
  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [Actions.TENANTS_DATAGRID_FETCH]),
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Tenants",
      isHeaderOutside: true,
      buttons: [
        {
          role: "primary",
          title: "Add tenant",
          icon: "mdi-plus",
          action: () => toggleDialog(Dialogs.TENANT_CREATE, {}),
          isAllowed: hasUserPermissions(Namespaces.TENANT, [
            Permissions.CREATE_ACCOUNT_ALL,
          ]),
        },
      ],
      rowActions: (row) => ({
        primary: {
          action: () =>
            this.$router.push({
              name: Routes.TENANT_DETAIL_BASIC,
              params: { idTenant: row.id },
            }),
          icon: "mdi-cog",
          title: "Settings",
          isAllowed: hasUserPermissions(Namespaces.TENANT, [
            Permissions.VIEW_ACCOUNT,
          ]),
        },
        secondary: useTenantActions(row),
      }),
      columnModificators: {
        "item.isCompany": ({ item }) =>
          item.isCompany ? (
            <VIcon color="success">mdi-check-circle</VIcon>
          ) : (
            <VIcon color="error">mdi-close-circle</VIcon>
          ),
      },
      headers: [
        { text: "Name", value: "invoicingName" },
        { text: "Vat number", value: "vatNumber" },
        { text: "Address", value: "address" },
        { text: "Company", value: "isCompany" },
      ],
      filters: [{ name: "invoicingName" }],
    };

    this.dataGridConfig = dataGridConfig;
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.TENANTS_DATAGRID_FETCH}
          data={this.GET_DATAGRID_DATA(DataGrids.TENANTS)}
          configuration={this.dataGridConfig}
          isFetching={this.IS_ACTION_FETCHING(Actions.TENANTS_DATAGRID_FETCH)}
        />
        <TenantCreateDialog />
        <TenantRemoveDialog />
      </div>
    );
  },
});

export default TenantsDataGrid;
