export const Namespaces = {
  CALLSCRIPT: "CallScript",
  COMPONENT: "Component",
  TENANT: "AccessManagement",
  AUDIT_LOG: "Auditlog",
  CAMPAIGN: "Campaign",
  GRAMMAR: "Grammar",
  LINE: "Line",
  MODULE: "Module",
  STATISTICS: "Statistics",
};

export const Permissions = {
  VIEW: "view",
  CREATE: "create",
  DELETE: "delete",
  EDIT: "edit",
  CREATE_ACCOUNT: "createaccount",
  EDIT_ACCOUNT: "editaccount",
  EDIT_ACCOUNT_ALL: "editaccount-allclients",
  DELETE_ACCOUNT: "deleteaccount",
  CREATE_ACCOUNT_ALL: "createaccount-allclients",
  DELETE_ACCOUNT_ALL: "deleteaccount-allclients",
  DELETE_ROLE: "deleterole",
  CREATE_ROLE: "createrole",
  VIEW_ROLE: "viewrole",
  VIEW_ACCOUNT: "viewaccount",
  VIEW_ACCOUNT_ALL: "viewaccount-allclients",
  VIEW_CALLSCRIPT: "viewdesign",
  VIEW_ALL_CLIENTS: "view-allclients",
  EDIT_API_SECRET: "editapisecret",
};
