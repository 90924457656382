import Dialogs from "@/constants/dialogs";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VIcon,
  VSimpleTable,
  VSpacer,
  VToolbar,
  VToolbarTitle,
} from "vuetify/lib";
import { mapActions } from "vuex";
import SectionToolbar from "../SectionToolbar";
import TenantNotifyGroupDialog from "../TenantNotifyGroupDialog";

const TenantNotifications = Vue.extend({
  data: () => ({
    notifyGroups: [],
  }),

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.TENANT_FETCH_NOTIFICATION_GROUPS,
    ]),

    async fetchData() {
      const { content } = await this.TENANT_FETCH_NOTIFICATION_GROUPS({
        idTenant: this.$route.params.idTenant,
      });
      this.notifyGroups = content;
    },
  },

  created() {
    this.fetchData();
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar
          title="Notification groups"
          action={{
            action: () => toggleDialog(Dialogs.TENANT_NOTIFY_GROUP, {}),
            title: "Add group",
            icon: "mdi-plus",
          }}
        />

        <VCard flat>
          <VCardText>
            {this.notifyGroups.length > 0 ? (
              <VSimpleTable>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Contacts</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {this.notifyGroups?.map(({ name, type, options }) => (
                    <tr>
                      <td>{name}</td>
                      <td>{type}</td>
                      <td>{JSON.parse(options).contacts}</td>
                      <td>
                        <VBtn
                          class="tertiary white--text"
                          fab
                          xSmall
                          onClick={() => null}
                        >
                          <VIcon>mdi-pencil</VIcon>
                        </VBtn>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </VSimpleTable>
            ) : (
              <span>Tenant has no notification groups yet.</span>
            )}
          </VCardText>
        </VCard>
        <TenantNotifyGroupDialog />
      </div>
    );
  },
});

export default TenantNotifications;
