import ExternalLinks from "@/constants/externalLinks";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import AdministrationItem from "@/models/AdministrationItem";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCol,
  VIcon,
  VRow,
  VSpacer,
  VToolbar,
  VToolbarTitle,
} from "vuetify/lib";

const AdministrationGuide = Vue.extend({
  data: () => ({
    sections: [
      {
        title: "Administration",
        items: [
          {
            title: "Components",
            icon: "mdi-puzzle",
            route: Routes.COMPONENTS,
          },
          {
            title: "Tenants",
            icon: "mdi-account-group",
            route: Routes.TENANTS_OVERVIEW,
          },
          {
            title: "Users",
            icon: "mdi-account-details",
            route: Routes.USERS_OVERVIEW,
          },
          {
            title: "Permission groups",
            icon: "mdi-account-key",
            route: Routes.PERMISSION_GROUPS_OVERVIEW,
          },
          {
            title: "Modules",
            icon: "mdi-application-cog-outline",
            route: Routes.MODULE_OVERVIEW,
          },
          {
            title: "Lines",
            icon: "mdi-deskphone",
            route: Routes.LINES_OVERVIEW,
          },
          {
            title: "Grammars",
            icon: "mdi-alphabetical",
            route: Routes.GRAMMARS_OVERVIEW,
          },
          {
            title: "Tenant statistics",
            icon: "mdi-chart-bar",
            route: Routes.TENANT_STATISTICS_ALL,
            permissions: [
              Namespaces.STATISTICS,
              [Permissions.VIEW_ALL_CLIENTS],
            ],
          },
          {
            title: "Audit log",
            icon: "mdi-clipboard-text-search-outline",
            route: Routes.AUDIT_LOG,
            permissions: [Namespaces.AUDIT_LOG, [Permissions.VIEW]],
          },
        ],
      },
      {
        title: "Tools",
        items: [
          {
            title: "Designer manual",
            icon: "mdi-book-open-variant",
            route: ExternalLinks.DESIGNER_MANUAL,
            isExternal: true,
          },
          {
            title: "TTS generator",
            icon: "mdi-text-to-speech",
            route: Routes.TTS_GENERATOR,
          },
          {
            title: "Packages manager",
            icon: "mdi-package-variant-closed",
            route: Routes.PACKAGE_MANAGER_INSTALL,
          },
        ],
      },
    ],
  }),

  render(): VNode {
    return (
      <div>
        {this.sections.map(({ title, items }: any) => (
          <div class="mb-7">
            <VRow>
              <VCol>
                <h2 class="text-h4 font-weight-bold primary--text">{title}</h2>
              </VCol>
            </VRow>
            <VRow class="mt-2">
              {items.map(
                ({
                  title,
                  icon,
                  route,
                  isExternal,
                  permissions = [null, null],
                }: AdministrationItem) =>
                  hasUserPermissions(permissions[0], permissions[1]) && (
                    <VCol cols={12} sm={6} md={4} lg={3}>
                      <VCard
                        to={isExternal ? null : { name: route }}
                        href={isExternal ? route : null}
                        target={isExternal ? "_blank" : null}
                        hover
                      >
                        <VToolbar flat>
                          <VToolbarTitle class="text-subtitle-1">
                            {title}
                          </VToolbarTitle>
                          <VSpacer />
                          {icon && <VIcon color="primary">{icon}</VIcon>}
                        </VToolbar>
                      </VCard>
                    </VCol>
                  )
              )}
            </VRow>
          </div>
        ))}
      </div>
    );
  },
});

export default AdministrationGuide;
