import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions, mapGetters } from "vuex";
import VueJsonEditor from "vue-json-editor";
import {
  VForm,
  VTab,
  VTabItem,
  VTabs,
  VTabsItems,
  VTextField,
} from "vuetify/lib";

const Fields = {
  MaxInboundCalls: "MaxInboundCalls",
  MaxOutboundCalls: "MaxOutboundCalls",
};

const LineCallbotConfigurationForm = Vue.extend({
  data: () => ({
    configuration: {},
    activeTab: 0,
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.LINE_CALLBOT_CONFIG_FETCH]),

    async handleSubmit(): Promise<void> {
      const { isFormSubmited } = await submitForm({
        actionId: Actions.LINE_CALLBOT_CONFIG_EDIT,
        values: { content: JSON.stringify(this.configuration) },
        url: ApiEndpoints.LINE_CALLBOT_SETTINGS,
        params: { idDialModuleLine: this.$route.params.idDialModuleLine },
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.LINE_EDIT_CALLBOT_CONFIG, {});
        pushNotify(Notify.SUCCESS, "Line configuration edited successfully");

        this.LINE_CALLBOT_CONFIG_FETCH({
          idDialModuleLine: this.$route.params.idDialModuleLine,
        });
      }
    },
  },

  created() {
    this.configuration = {
      ...this.INSPECTED_LINE?.callbotSettings?.content,
    };
  },

  render(): VNode {
    return (
      <div>
        <VTabs grow vModel={this.activeTab}>
          <VTab>Form</VTab>
          <VTab>Source</VTab>
        </VTabs>

        <VTabsItems vModel={this.activeTab}>
          <VTabItem>
            <VForm class="pt-3">
              <VTextField
                vModel={this.configuration[Fields.MaxInboundCalls]}
                type="number"
                label="Max Inbound Calls"
              />
              <VTextField
                vModel={this.configuration[Fields.MaxOutboundCalls]}
                type="number"
                label="Max Outbound Calls"
              />
            </VForm>
          </VTabItem>
          <VTabItem>
            <VueJsonEditor vModel={this.configuration} mode="code" />
          </VTabItem>
        </VTabsItems>
      </div>
    );
  },
});

export default LineCallbotConfigurationForm;
