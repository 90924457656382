export const CALL_STATUS_ICONS = {
  "-1": {
    icon: "mdi-phone-missed",
    color: "warning",
  },
  1: {
    icon: "mdi-phone",
    color: "accent",
  },
  3: {
    icon: "mdi-phone-check",
    color: "success",
  },
  11: {
    icon: "mdi-alert-octagon",
    color: "error",
  },
};

export const CAMPAIGN_STATUS_ICONS = {
  0: {
    icon: "mdi-application-cog",
    color: "gray",
  },
  1: {
    icon: "mdi-plus",
    color: "gray",
  },
  2: {
    icon: "mdi-credit-card-clock",
    color: "gray",
  },
  3: {
    icon: "mdi-account-clock",
    color: "gray",
  },
  4: {
    icon: "mdi-tools",
    color: "warning",
  },
  5: {
    icon: "mdi-clipboard-text-clock",
    color: "accent",
  },
  6: {
    icon: "mdi-cancel",
    color: "error",
  },
  7: {
    icon: "mdi-pause-circle",
    color: "warning",
  },
  8: {
    icon: "mdi-progress-check",
    color: "success",
  },
  9: {
    icon: "mdi-check-all",
    color: "gray",
  },
};

export const DIAL_LOG_STATUS_TYPE = {
  0: {
    name: "Start",
    icon: "mdi-cog",
    color: "primary",
  },
  1: {
    name: "Pickup",
    icon: "mdi-phone",
    color: "primary",
  },
  2: {
    name: "Hangup",
    icon: "mdi-phone-hangup",
    color: "primary",
  },
  3: {
    name: "Robot",
    icon: "mdi-robot",
    color: "accent",
  },
  4: {
    name: "Audio play",
    icon: "mdi-volume-high",
    color: "primary",
  },
  5: {
    name: "Human",
    icon: "mdi-account",
    color: "tertiary",
  },
  6: {
    name: "Tone in",
    icon: "mdi-cog",
    color: "primary",
  },
  7: {
    name: "SMS sent",
    icon: "mdi-message-processing",
    color: "primary",
  },
  8: {
    name: "Error",
    icon: "mdi-alert-octagon",
    color: "error",
  },
  9: {
    name: "Email sent",
    icon: "mdi-email-check",
    color: "primary",
  },
  10: {
    name: "Tone out",
    icon: "mdi-cog",
    color: "primary",
  },
  11: {
    name: "Call transfer",
    icon: "mdi-phone-forward",
    color: "primary",
  },
};

export const AUDIT_TYPE_ICONS = {
  1: {
    name: "Information",
    icon: "mdi-information",
    color: "accent",
  },
  2: {
    name: "Warning",
    icon: "mdi-alert",
    color: "warning",
  },
  3: {
    name: "Error",
    icon: "mdi-alert-octagon",
    color: "error",
  },
};

export const FLOW_TYPE_ICONS = {
  0: {
    name: "Voice",
    icon: "microphone",
  },
  1: {
    name: "Chat",
    icon: "chat-processing-outline",
  },
  2: {
    name: "RPA",
    icon: "cog",
  },
};
