import Vue, { VNode } from "vue";
import { VList, VListItem } from "vuetify/lib";

const DialLogAlertList = Vue.extend({
  props: {
    data: Array,
    title: String,
    titleColor: String,
  },

  render(): VNode {
    return (
      <VList dense class="pt-0 mb-2">
        <h3 class={`text-h6 ${this.titleColor}--text mb-2`}>{this.title}</h3>
        {this.data?.map((item: any) => (
          <VListItem>{item.text}</VListItem>
        ))}
      </VList>
    );
  },
});

export default DialLogAlertList;
