import { Modules, Mutations } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCardText,
  VToolbar,
  VToolbarTitle,
  VSpacer,
  VBtn,
  VIcon,
  VRow,
  VCol,
  VSkeletonLoader,
} from "vuetify/lib";
import { mapMutations } from "vuex";
import DataTextOverview from "../DataTextOverview";
import getPath from "@/helpers/getPath";
import Dialog from "../Dialog";

const EditableOverviewSection = Vue.extend({
  props: {
    data: Object,
    editDialog: String,
    fetchData: Function,
    isDataFetching: Boolean,
    configuration: Object,
    action: String,
    overviewComponent: Object,
  },

  computed: {
    overviewData(): any[] {
      if (!this.data || !this.configuration?.columns) {
        return [];
      }

      return this.configuration.columns.map(({ name, title }: any) => {
        let mappedValue = null;

        try {
          mappedValue = getPath(this.data.content, name);
        } catch {
          mappedValue = "";
        }

        const formattedValue = this.configuration?.modificators?.[name]
          ? this.configuration.modificators[name](this.data.content)
          : mappedValue;

        return {
          title,
          value:
            !formattedValue || formattedValue === null || formattedValue === ""
              ? "-"
              : formattedValue,
        };
      });
    },
  },

  methods: {
    ...mapMutations(Modules.DIALOGS, [Mutations.TOGGLE_DIALOG]),
  },

  created() {
    this.fetchData ? this.fetchData() : null;
  },

  render(): VNode {
    const scopedSlots: any = this.$scopedSlots;

    return (
      <div>
        <VCard flat>
          <VToolbar flat>
            <VToolbarTitle
              class="primary--text font-weight-bold text-h5"
              inset
              vertical
            >
              {this.configuration.title}
            </VToolbarTitle>
            <VSpacer />
            {this.data?.editableFields?.length > 0 && (
              <VBtn
                small
                fab
                color="primary"
                onClick={() => this.TOGGLE_DIALOG({ name: this.editDialog })}
                class="ml-2 tertiary"
              >
                <VIcon>mdi-pencil</VIcon>
              </VBtn>
            )}
          </VToolbar>
          <VCardText class="pt-0">
            {this.isDataFetching ? (
              <VRow class="mt-4">
                {this.configuration?.columns?.map(() => (
                  <VCol md={6}>
                    <VSkeletonLoader type="chip" />
                  </VCol>
                ))}
              </VRow>
            ) : (
              <div>
                {this.overviewComponent ? (
                  this.overviewComponent
                ) : (
                  <DataTextOverview items={this.overviewData} />
                )}
              </div>
            )}
          </VCardText>
        </VCard>
        {this.data?.editableFields?.length > 0 && (
          <Dialog
            width="500"
            id={this.editDialog}
            title="Editation"
            actions={[
              {
                action: () =>
                  scopedSlots.form()?.[0].context?.$refs.form?.handleSubmit(),
                title: "Apply",
                icon: "mdi-check",
                id: this.action,
              },
            ]}
            toggleHandler={(isActive: boolean) =>
              isActive
                ? scopedSlots.form()?.[0].context?.$refs.form?.initValues()
                : null
            }
          >
            {scopedSlots.form()}
          </Dialog>
        )}
      </div>
    );
  },
});

export default EditableOverviewSection;
