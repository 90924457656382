import { Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VDatePicker,
  VForm,
  VIcon,
  VMenu,
  VTextField,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import TenantSelect from "../TenantSelect";

const StatisticsControlPanel = Vue.extend({
  props: {
    fetchStatistics: Function,
    fetchCsvData: Function,
    fetchParams: Object,
    fetchCsvAction: String,
    defaultFilters: Object,
    withTenantSelection: Boolean,
    fetchCsvReports: Function,
    fetchCsvReportAction: String,
  },

  data: () => ({
    filters: {
      from: "",
      to: "",
      roundInterval: "",
      idTenant: "",
    },
    menus: {
      from: false,
      to: false,
    },
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    getTenantFilter(): string {
      return this.filters.idTenant;
    },
  },

  created() {
    this.filters = {
      ...this.defaultFilters,
      ...(this.defaultFilters.idTenant && {
        idTenant: parseInt(this.defaultFilters.idTenant),
      }),
    };
  },

  render(): VNode {
    return (
      <div>
        <VForm class="d-flex">
          {this.withTenantSelection && (
            <TenantSelect
              vModel={this.filters.idTenant}
              options={{ outlined: true, dense: true }}
            />
          )}
          <VMenu
            vModel={this.menus.from}
            offsetY
            transition="scale-transition"
            maxWidth="290"
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <VTextField
                  class={`mr-4 ${this.withTenantSelection ? "ml-4" : ""}`}
                  outlined
                  dense
                  vModel={this.filters.from}
                  label="Date from"
                  readonly
                  prependIcon="mdi-calendar"
                  {...{ on, attrs }}
                />
              ),
            }}
          >
            <VDatePicker vModel={this.filters.from} firstDayOfWeek={1} />
          </VMenu>

          <VMenu
            vModel={this.menus.to}
            offsetY
            transition="scale-transition"
            maxWidth="290"
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <VTextField
                  class="mr-4"
                  outlined
                  dense
                  vModel={this.filters.to}
                  label="Date to"
                  readonly
                  prependIcon="mdi-calendar"
                  {...{ on, attrs }}
                />
              ),
            }}
          >
            <VDatePicker vModel={this.filters.to} firstDayOfWeek={1} />
          </VMenu>

          <VTextField
            type="number"
            dense
            outlined
            label="Round interval"
            vModel={this.filters.roundInterval}
          />
        </VForm>
        <div class="d-flex justify-end">
          {this.fetchCsvData && (
            <div class="d-flex justify-end">
              <VBtn
              disabled={
                this.filters.idTenant === "" && this.withTenantSelection
              }
              loading={this.IS_ACTION_FETCHING(this.fetchCsvReportAction)}
              onClick={() => 
              this.fetchCsvReports({
                idTenant: (this.withTenantSelection) ? this.filters.idTenant : this.fetchParams.idTenant,
                from: new Date(this.filters.from),
                  to: new Date(this.filters.to),
                  roundInterval: this.filters.roundInterval
              })
              }
              rounded
              class="secondary primary--text mr-3"
              >Stáhnout CSV přehled</VBtn>
              <VBtn
              disabled={
                this.filters.idTenant === "" && this.withTenantSelection
              }
              loading={this.IS_ACTION_FETCHING(this.fetchCsvAction)}
              onClick={() =>
                this.fetchCsvData({
                  ...this.fetchParams,
                  from: new Date(this.filters.from),
                  to: new Date(this.filters.to),
                  ...(this.withTenantSelection && {
                    idTenant: this.filters.idTenant,
                  }),
                })
              }
              rounded
              class="secondary primary--text mr-3"
            >
              <VIcon left>mdi-microsoft-excel</VIcon>Download report
            </VBtn>
            </div>
          )}

          <VBtn
            disabled={this.filters.idTenant === "" && this.withTenantSelection}
            onClick={() => this.fetchStatistics(this.filters)}
            rounded
            class="tertiary white--text"
          >
            <VIcon left>mdi-check</VIcon>Apply
          </VBtn>
        </div>
      </div>
    );
  },
});

export default StatisticsControlPanel;
