import Vue, { VNode } from "vue";
import {
  VAvatar,
  VCard,
  VDivider,
  VIcon,
  VList,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemIcon,
  VListItemSubtitle,
  VListItemTitle,
  VMenu,
} from "vuetify/lib";
import Routes from "@/constants/routes";
import { logout } from "@vocalls/sso-auth-service";
import ActionHandler from "@/models/actions/ActionHandler";
import { mapGetters } from "vuex";
import { Getters, Modules } from "@/models/store";

const UserProfileMenu = Vue.extend({
  data: () => ({
    menuItems: [] as ActionHandler[],
  }),

  computed: {
    ...mapGetters(Modules.USER, [Getters.USER_INFO]),

    avatarText(): string {
      const { firstname, lastname, username } = this.USER_INFO;

      if (firstname && lastname) {
        return `${firstname.charAt(0)}${lastname.charAt(0)}`.toUpperCase();
      }

      if (firstname) {
        return firstname.charAt(0).toUpperCase();
      }

      if (username) {
        return username.charAt(0).toUpperCase();
      }

      return "";
    },
  },

  created() {
    this.menuItems = [
      {
        title: "Profile settings",
        icon: "mdi-cog",
        action: () => this.$router.push({ name: Routes.USER_PROFILE_BASIC }),
      },
      {
        title: "Sign out",
        icon: "mdi-logout",
        action: () => logout(),
      },
    ];
  },

  render(): VNode {
    const { firstname, lastname, username, tenant } = this.USER_INFO;

    return (
      <VMenu
        minWidth={250}
        scopedSlots={{
          activator: ({ on, attrs }: never) => (
            <VAvatar color="secondary" size="40" {...{ on, attrs }}>
              {this.avatarText}
            </VAvatar>
          ),
        }}
      >
        <VCard>
          <VList>
            <VListItem>
              <VListItemAvatar color="secondary" class="justify-center">
                {this.avatarText}
              </VListItemAvatar>
              <VListItemContent>
                <VListItemTitle class="primary--text font-weight-bold">
                  {firstname && lastname
                    ? `${firstname} ${lastname}`
                    : username}
                </VListItemTitle>
                {tenant && <VListItemSubtitle>{tenant}</VListItemSubtitle>}
              </VListItemContent>
            </VListItem>
          </VList>
          <VDivider />
          <VList nav dense>
            {this.menuItems.map(({ title, icon, action }) => (
              <VListItem onClick={() => action()}>
                <VListItemIcon>
                  <VIcon>{icon}</VIcon>
                </VListItemIcon>
                <VListItemContent>
                  <VListItemTitle>{title}</VListItemTitle>
                </VListItemContent>
              </VListItem>
            ))}
          </VList>
        </VCard>
      </VMenu>
    );
  },
});

export default UserProfileMenu;
