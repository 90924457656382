import Dialogs from "@/constants/dialogs";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import { mapGetters } from "vuex";
import { Getters, Modules } from "@/models/store";
import UserProfileAccountCard from "../UserProfileAccountCard";

const UserProfileAccountsDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    accounts(): any[] {
      return this.GET_DIALOG_DATA(Dialogs.USER_CONNECTED_ACCOUNTS)?.accounts;
    },
  },

  render(): VNode {
    return (
      <Dialog id={Dialogs.USER_CONNECTED_ACCOUNTS} title="Connect accounts">
        {this.accounts?.map((account) => (
          <UserProfileAccountCard {...{ props: account }} />
        ))}
      </Dialog>
    );
  },
});

export default UserProfileAccountsDialog;
