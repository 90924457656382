import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextarea } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";

const GrammarContentEditForm = Vue.extend({
  data: () => ({
    fields: {
      content: "",
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_GRAMMAR]),
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.GRAMMAR_OVERVIEW_FETCH]),

    async handleSubmit() {
      const instance = this.INSPECTED_GRAMMAR.basic.content;
      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.GRAMMAR_BASIC,
        values: {
          name: instance.name,
          idClient: instance.idClient || null,
          content: this.fields.content.replace(/[/\r?\n/]/gm, "\r\n"),
        },
        params: { idGrammar: this.$route.params.idGrammar },
        actionId: Actions.GRAMMAR_OVERVIEW_EDIT,
      });

      if (isFormSubmited) {
        toggleDialog(Dialogs.GRAMMAR_EDIT_CONTENT, {});
        pushNotify(Notify.SUCCESS, "Grammar created successfully");

        this.GRAMMAR_OVERVIEW_FETCH({
          idGrammar: this.$route.params.idGrammar,
        });
      }
    },
  },

  created() {
    this.fields.content = this.INSPECTED_GRAMMAR?.basic?.content?.content;
  },

  render(): VNode {
    return (
      <VForm ref="form">
        <VTextarea vModel={this.fields.content} label="Content" auto-grow autofocus />
      </VForm>
    );
  },
});

export default GrammarContentEditForm;
