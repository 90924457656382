import { ActionTree } from "vuex";
import { Actions } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import ApiEndpoints from "@/constants/apiEndpoints";
import DataGridsState from "@/models/store/DataGridsState";

const fetchDataActions: ActionTree<DataGridsState, []> = {
  [Actions.MODULES_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.DIAL_MODULES,
      actionId: Actions.MODULES_DATAGRID_FETCH,
      url: ApiEndpoints.DIAL_MODULES,
      ...params,
    });
  },

  [Actions.LINES_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.LINES,
      actionId: Actions.LINES_DATAGRID_FETCH,
      url: ApiEndpoints.DIAL_MODULE_LINES,
      ...params,
    });
  },

  [Actions.DIAL_LOGS_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.DIAL_LOGS,
      actionId: Actions.DIAL_LOGS_DATAGRID_FETCH,
      url: ApiEndpoints.DIAL_LOGS,
      ...params,
    });
  },

  [Actions.CALLSCRIPTS_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.CALL_SCRIPTS,
      actionId: Actions.CALLSCRIPTS_DATAGRID_FETCH,
      url: ApiEndpoints.FLOW,
      ...params,
    });
  },

  [Actions.FLOW_VERSION_HISTORY_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.FLOW_VERSION_HISTORY,
      actionId: Actions.FLOW_VERSION_HISTORY_DATAGRID_FETCH,
      url: ApiEndpoints.FLOW_VERSION_HISTORY,
      ...params,
    });
  },

  [Actions.CAMPAIGNS_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.CAMPAIGNS,
      actionId: Actions.CAMPAIGNS_DATAGRID_FETCH,
      url: ApiEndpoints.CAMPAIGNS,
      ...params,
    });
  },

  [Actions.GRAMMARS_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.GRAMMARS,
      actionId: Actions.GRAMMARS_DATAGRID_FETCH,
      url: ApiEndpoints.GRAMMARS,
      ...params,
    });
  },

  [Actions.USERS_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.USERS,
      actionId: Actions.USERS_DATAGRID_FETCH,
      url: ApiEndpoints.USERS,
      ...params,
    });
  },

  [Actions.TENANTS_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.TENANTS,
      actionId: Actions.TENANTS_DATAGRID_FETCH,
      url: ApiEndpoints.TENANTS,
      ...params,
    });
  },

  [Actions.AUDIT_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.AUDIT,
      actionId: Actions.AUDIT_DATAGRID_FETCH,
      url: ApiEndpoints.AUDIT,
      ...params,
    });
  },

  [Actions.PERMISSION_GROUPS_DATAGRID_FETCH]: ({ dispatch }, params) => {
    dispatch(Actions.DATAGRID_FETCH, {
      dataGrid: DataGrids.PERMISSION_GROUPS,
      actionId: Actions.PERMISSION_GROUPS_DATAGRID_FETCH,
      url: ApiEndpoints.PERMISSION_GROUPS,
      ...params,
    });
  },
};

export default fetchDataActions;
