import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCardText,
  VCol,
  VDivider,
  VRow,
  VSimpleTable,
} from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import DataTextOverview from "../DataTextOverview";
import SectionToolbar from "../SectionToolbar";
import UserApiSecrets from "../UserApiSecrets";
import UserRolesOverview from "../UserRolesOverview";

const UserProfilePermissions = Vue.extend({
  data: () => ({
    idUser: "",
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_USER]),
    ...mapGetters(Modules.USER, [Getters.USER_INFO, Getters.USER_ACCOUNTS]),

    enabledRoles(): any[] {
      const enabledRoles: any[] = [];
      this.INSPECTED_USER?.roles?.map((role: any) =>
        role.hasRole ? enabledRoles.push(role) : null
      );
      return enabledRoles;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.USER_ROLES_FETCH]),
  },

  created() {
    this.idUser = this.USER_INFO?.id;
    this.idUser ? this.USER_ROLES_FETCH({ idUser: this.idUser }) : null;
  },

  render(): VNode {
    return (
      <div>
        <UserRolesOverview data={this.enabledRoles} />
        <VDivider class="mb-3 mt-4" />
        <UserApiSecrets idUser={this.idUser} />
      </div>
    );
  },
});

export default UserProfilePermissions;
