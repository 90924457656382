import { RouteConfig } from "vue-router";
import Routes, { DEFAULT_TITLE } from "@/constants/routes";
import FlowsDataGrid from "@/components/FlowsDataGrid";
import CampaignsDataGrid from "@/components/CampaignsDataGrid";
import ModuleDetail from "@/components/ModuleDetail";
import ModulesDataGrid from "@/components/ModulesDataGrid";
import ModuleOverview from "@/components/ModuleOverview";
import LinesDataGrid from "@/components/LinesDataGrid";
import DialLogDataGrid from "@/components/DialLogDataGrid";
import AdministrationGuide from "@/components/AdministrationGuide";
import RouterNavigation from "@/components/RouterNavigation";
import LineOverview from "@/components/LineOverview";
import LineDetail from "@/components/LineDetail";
import FlowDetail from "@/components/FlowDetail";
import FlowOverview from "@/components/FlowOverview";
import FlowVersionHistoryDataGrid from "@/components/FlowVersionHistoryDataGrid";
import NotFound from "@/components/NotFound";
import GrammarsDataGrid from "@/components/GrammarsDataGrid";
import GrammarDetail from "@/components/GrammarDetail";
import GrammarOverview from "@/components/GrammarOverview";
import TtsGenerator from "@/components/TtsGenerator";
import PackageManager from "@/components/PackageManager";
import UsersDataGrid from "@/components/UsersDataGrid";
import TenantsDataGrid from "@/components/TenantsDataGrid";
import TenantDetail from "@/components/TenantDetail";
import TenantOverview from "@/components/TenantOverview";
import TenantNotifications from "@/components/TenantNotifications";
import UserDetail from "@/components/UserDetail";
import UserOverview from "@/components/UserOverview";
import LineMonitoring from "@/components/LineMonitoring";
import AuditDataGrid from "@/components/AuditDataGrid";
import UserProfileDetail from "@/components/UserProfileDetail";
import UserProfileOverview from "@/components/UserProfileOverview";
import NotAuthorized from "@/components/NotAuthorized";
import ComponentsDataGrid from "@/components/ComponentsDataGrid";
import { Namespaces, Permissions } from "@/constants/permissions";
import PermissionGroupsDataGrid from "@/components/PermissionGroupsDataGrid";
import PermissionGroupDetail from "@/components/PermissionGroupDetail";
import PermissionGroupOverview from "@/components/PermissionGroupOverview";
import UserProfilePermissions from "@/components/UserProfilePermissions";
import PackageManagerInstall from "@/components/PackageManagerInstall";
import UserProfileSecurity from "@/components/UserProfileSecurity";
import PackageManagerCreate from "@/components/PackageManagerCreate";
import Reporting from "@/components/Reporting";
import TenantStatistics from "@/components/TenantStatistics";
import CampaignDashboard from "@/components/CampaignDashboard";
import LineStatistics from "@/components/LineStatistics";
import TenantStatisticsAll from "@/components/TenantStatisticsAll";

const generatePageTitle = (title: string): string => {
  return `${title} - ${DEFAULT_TITLE}`;
};

const routesConfiguration: RouteConfig[] = [
  {
    path: "/",
    redirect: { name: Routes.FLOW_OVERVIEW },
  },
  {
    path: "/flows",
    component: RouterNavigation,
    children: [
      {
        path: "",
        name: Routes.FLOW_OVERVIEW,
        component: FlowsDataGrid,
        meta: {
          title: generatePageTitle("Flows"),
        },
      },
      {
        path: "detail/:idCallScript",
        component: FlowDetail,
        meta: {
          permissions: [Namespaces.CALLSCRIPT, [Permissions.VIEW]],
        },
        children: [
          {
            path: "",
            redirect: { name: Routes.FLOW_DETAIL_BASIC },
          },
          {
            path: "basic",
            name: Routes.FLOW_DETAIL_BASIC,
            component: FlowOverview,
          },
          {
            path: "history",
            name: Routes.FLOW_DETAIL_VERSION_HISTORY,
            component: FlowVersionHistoryDataGrid,
          },
          {
            path: "dialLogs",
            name: Routes.FLOW_DETAIL_DIAL_LOGS,
            component: DialLogDataGrid,
            props: (route) => ({
              params: { idCallScript: route.params.idCallScript },
            }),
          },
        ],
      },
    ],
  },
  {
    path: "/campaigns",
    component: RouterNavigation,
    children: [
      {
        path: "",
        name: Routes.CAMPAIGN,
        component: CampaignsDataGrid,
        meta: {
          title: generatePageTitle("Campaigns"),
        },
      },
      {
        path: "detail/:idCampaign",
        name: Routes.CAMPAIGN_DETAIL,
        component: CampaignDashboard,
      },
    ],
  },
  {
    path: "/administration",
    component: RouterNavigation,
    children: [
      {
        path: "",
        name: Routes.ADMINISTRATION,
        component: AdministrationGuide,
        meta: {
          title: generatePageTitle("Administration"),
        },
      },
      {
        path: "modules",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.MODULE_OVERVIEW,
            component: ModulesDataGrid,
            meta: {
              title: generatePageTitle("Modules"),
            },
          },
          {
            path: "detail/:idDialModule",
            component: ModuleDetail,
            meta: {
              permissions: [Namespaces.MODULE, [Permissions.VIEW]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.MODULE_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.MODULE_DETAIL_BASIC,
                component: ModuleOverview,
              },
              {
                path: "lines",
                name: Routes.MODULE_DETAIL_LINES,
                component: LinesDataGrid,
                props: { isInsideDetail: true },
              },
              {
                path: "dialLogs",
                name: Routes.MODULE_DETAIL_DIAL_LOGS,
                component: DialLogDataGrid,
                props: (route) => ({
                  params: { idDialModule: route.params.idDialModule },
                  showFlow: true,
                }),
              },
            ],
          },
        ],
      },
      {
        path: "lines",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.LINES_OVERVIEW,
            component: LinesDataGrid,
            meta: {
              title: generatePageTitle("Lines"),
            },
          },
          {
            path: "detail/:idDialModuleLine",
            component: LineDetail,
            meta: {
              permissions: [Namespaces.LINE, [Permissions.VIEW]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.LINE_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.LINE_DETAIL_BASIC,
                component: LineOverview,
              },
              {
                path: "monitoring",
                name: Routes.LINE_DETAIL_MONITORING,
                component: LineMonitoring,
              },
              {
                path: "statistics",
                name: Routes.LINE_DETAIL_STATISTICS,
                component: LineStatistics,
              },
              {
                path: "dialLogs",
                name: Routes.LINE_DETAIL_DIAL_LOGS,
                component: DialLogDataGrid,
                props: (route) => ({
                  params: { idDialModuleLine: route.params.idDialModuleLine },
                  showFlow: true,
                }),
              },
            ],
          },
        ],
      },
      {
        path: "grammars",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.GRAMMARS_OVERVIEW,
            component: GrammarsDataGrid,
            meta: {
              title: generatePageTitle("Grammars"),
            },
          },
          {
            path: "detail/:idGrammar",
            component: GrammarDetail,
            meta: {
              permissions: [Namespaces.GRAMMAR, [Permissions.VIEW]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.GRAMMARS_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.GRAMMARS_DETAIL_BASIC,
                component: GrammarOverview,
              },
            ],
          },
        ],
      },
      {
        path: "users",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.USERS_OVERVIEW,
            component: UsersDataGrid,
            meta: {
              title: generatePageTitle("Users"),
            },
          },
          {
            path: "detail/:idUser",
            component: UserDetail,
            meta: {
              permissions: [Namespaces.TENANT, [Permissions.VIEW_ACCOUNT]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.USER_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.USER_DETAIL_BASIC,
                component: UserOverview,
              },
            ],
          },
        ],
      },
      {
        path: "tenants",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.TENANTS_OVERVIEW,
            component: TenantsDataGrid,
            meta: {
              title: generatePageTitle("Tenants"),
            },
          },
          {
            path: "detail/:idTenant",
            component: TenantDetail,
            meta: {
              permissions: [Namespaces.TENANT, [Permissions.VIEW_ACCOUNT]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.TENANT_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.TENANT_DETAIL_BASIC,
                component: TenantOverview,
              },
              {
                path: "users",
                name: Routes.TENANT_DETAIL_USERS,
                component: UsersDataGrid,
                props: {
                  isInsideDetail: true,
                },
              },
              {
                path: "statistics",
                name: Routes.TENANT_DETAIL_STATISTICS,
                component: TenantStatistics,
              },
              {
                path: "notifications",
                name: Routes.TENANT_DETAIL_NOTIFICATIONS,
                component: TenantNotifications,
              },
            ],
          },
        ],
      },
      {
        path: "permission-groups",
        component: RouterNavigation,
        children: [
          {
            path: "",
            name: Routes.PERMISSION_GROUPS_OVERVIEW,
            component: PermissionGroupsDataGrid,
            meta: {
              title: generatePageTitle("Permission groups"),
            },
          },
          {
            path: "detail/:idRole",
            component: PermissionGroupDetail,
            meta: {
              permissions: [Namespaces.TENANT, [Permissions.VIEW_ROLE]],
            },
            children: [
              {
                path: "",
                redirect: { name: Routes.PERMISSION_GROUP_DETAIL_BASIC },
              },
              {
                path: "basic",
                name: Routes.PERMISSION_GROUP_DETAIL_BASIC,
                component: PermissionGroupOverview,
              },
            ],
          },
        ],
      },
      {
        path: "components",
        name: Routes.COMPONENTS,
        component: ComponentsDataGrid,
        meta: {
          title: generatePageTitle("Components"),
        },
        props: {
          isComponentsOnly: true,
        },
      },
      {
        path: "tenant-statistics",
        name: Routes.TENANT_STATISTICS_ALL,
        component: TenantStatisticsAll,
        meta: {
          title: generatePageTitle("Tenant statistics"),
          permissions: [Namespaces.STATISTICS, [Permissions.VIEW_ALL_CLIENTS]],
        },
      },
      {
        path: "tts-generator",
        name: Routes.TTS_GENERATOR,
        component: TtsGenerator,
        meta: {
          title: generatePageTitle("TTS Generator"),
        },
      },
      {
        path: "package-manager",
        component: PackageManager,
        meta: {
          title: generatePageTitle("Package manager"),
        },
        children: [
          {
            path: "",
            redirect: { name: Routes.PACKAGE_MANAGER_INSTALL },
          },
          {
            path: "install",
            name: Routes.PACKAGE_MANAGER_INSTALL,
            component: PackageManagerInstall,
          },
          {
            path: "create",
            name: Routes.PACKAGE_MANAGER_CREATE,
            component: PackageManagerCreate,
          },
        ],
      },
      {
        path: "audit",
        name: Routes.AUDIT_LOG,
        component: AuditDataGrid,
        meta: {
          title: generatePageTitle("Audit log"),
          permissions: [Namespaces.AUDIT_LOG, [Permissions.VIEW]],
        },
      },
      {
        path: "user-profile",
        component: UserProfileDetail,
        children: [
          {
            path: "",
            redirect: { name: Routes.USER_PROFILE_BASIC },
          },
          {
            path: "basic",
            name: Routes.USER_PROFILE_BASIC,
            component: UserProfileOverview,
          },
          {
            path: "permissions",
            name: Routes.USER_DETAIL_PERMISSIONS,
            component: UserProfilePermissions,
          },
          {
            path: "security",
            name: Routes.USER_DETAIL_SECURITY, 
            component: UserProfileSecurity,
          },
        ],
      },
    ],
  },
  {
    path: "/reporting",
    name: Routes.REPORTING,
    component: Reporting,
  },
  {
    path: "/auth-refresh",
    component: RouterNavigation,
  },
  {
    path: "/auth-callback",
    component: RouterNavigation,
  },
  {
    path: "/not-authorized",
    component: NotAuthorized,
    name: Routes.NOT_AUTHORIZED,
  },
  {
    path: "*",
    component: NotFound,
  },
];

export default routesConfiguration;
