const DataGrids = {
  DIAL_MODULES: "DIAL_MODULES",
  LINES: "LINES",
  DIAL_LOGS: "DIAL_LOGS",
  CALL_SCRIPTS: "CALL_SCRIPTS",
  FLOW_VERSION_HISTORY: "FLOW_VERSION_HISTORY",
  CAMPAIGNS: "CAMPAIGNS",
  GRAMMARS: "GRAMMARS",
  USERS: "USERS",
  TENANTS: "TENANTS",
  AUDIT: "AUDIT",
  PERMISSION_GROUPS: "PERMISSION_GROUPS",
};

export default DataGrids;
