const EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const TIME_PATTERN = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;

export const isRequired = (val: string): string | boolean =>
  val?.toString().replace(/\s/g, "") !== "" || "Field is required";

export const isEqualTo = (val: any, expectedVal: any): string | boolean =>
  val === expectedVal || "Value not match";

export const isEmail = (val: string): string | boolean =>
  EMAIL_PATTERN.test(val.toLowerCase()) || "Invalid email format";

export const isTime = (val: string): string | boolean =>
  TIME_PATTERN.test(val) || "Time must be in 'XX:YY' format";

export const hasMinLength = (val: string, length: number): string | boolean =>
  val.length >= length || `Field must contains at least ${length} characters`;

export const minValue = (val: string, minVal: number): string | boolean =>
  parseInt(val) >= minVal || `Minimum value is ${minVal}`;
