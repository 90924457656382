import Vue, { VNode } from "vue";
import DetailWithTabs from "@/components/DetailWithTabs";
import Routes from "@/constants/routes";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import useTenantActions from "@/hooks/useTenantActions";
import TenantRemoveDialog from "../TenantRemoveDialog";

const TenantDetail = Vue.extend({
  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_TENANT]),

    instanceData(): any {
      const invoicingName =
        this.INSPECTED_TENANT?.basic?.content?.invoicingName;
      const id = this.$route.params.idTenant;

      return { invoicingName, id };
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [Actions.TENANT_OVERVIEW_FETCH]),
  },

  render(): VNode {
    return (
      <div>
        <DetailWithTabs
          title={`Tenant - ${this.instanceData.invoicingName || ""}`}
          defaultReturnRoute={Routes.TENANTS_OVERVIEW}
          initDataFetch={() =>
            this.TENANT_OVERVIEW_FETCH({
              idTenant: this.instanceData.id,
            })
          }
          actions={useTenantActions(this.instanceData)}
          tabs={[
            {
              title: "General",
              path: Routes.TENANT_DETAIL_BASIC,
            },
            {
              title: "Users",
              path: Routes.TENANT_DETAIL_USERS,
            },
            {
              title: "Statistics",
              path: Routes.TENANT_DETAIL_STATISTICS,
            },
            {
              title: "Notifications",
              path: Routes.TENANT_DETAIL_NOTIFICATIONS,
            },
          ]}
        />
        <TenantRemoveDialog />
      </div>
    );
  },
});

export default TenantDetail;
