import { ActionTree } from "vuex";
import { Actions, Modules } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import { DEFAULT_TITLE } from "@/constants/routes";
import router from "@/router";
import toggleDialog from "@/helpers/toggleDialog";
import pushNotify from "@/helpers/pushNotify";
import Notify from "@/constants/notifications";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import downloadFile from "@/helpers/downloadFile";
import generateErrorMsg from "@/helpers/generateErrorMsg";
import { setStorageItem } from "@/helpers/userLocalStorage";

const instanceActions: ActionTree<InstancesState, []> = {
  [Actions.SECTION_DETAIL_FETCH]: async (
    _,
    { url, action, params, modify, error, withTitleChange, titleProp }
  ) => {
    const reqResponse = await createRequest({
      action,
      method: "GET",
      url,
      ...(params && { params }),
    });

    const { data, errors } = parseResponse(reqResponse);

    if (errors.length === 0) {
      modify(data);
      const title = titleProp ? titleProp : "name";

      withTitleChange
        ? (document.title = `${
            data?.content?.[title] || ""
          } - ${DEFAULT_TITLE}`)
        : null;
    } else {
      error();
    }
  },

  [Actions.INSTANCE_DUPLICATE]: async (
    _,
    { action, param, prefix, dialog, redirectRoute, endpoint }
  ) => {
    const requestRes = await createRequest({
      action,
      method: "GET",
      url: endpoint,
      params: { [param.id]: param.value },
    });

    const { errors, data } = parseResponse(requestRes);

    if (errors.length !== 0) {
      return;
    }

    router.push({
      name: redirectRoute,
      params: { [param.id]: data.id },
    });

    toggleDialog(dialog, {});
    pushNotify(Notify.SUCCESS, `${prefix} duplicated successfully`);
  },

  [Actions.INSTANCE_REMOVE]: async (
    { dispatch },
    {
      params,
      isDetailActive,
      endpoint,
      action,
      redirectRoute,
      dataGrid,
      dataGridFetch,
      dialog,
      prefix,
    }
  ) => {
    const requestRes = await createRequest({
      action,
      method: "DELETE",
      url: endpoint,
      params,
    });

    const { errors } = parseResponse(requestRes);

    if (errors.length !== 0) {
      return;
    }

    if (isDetailActive) {
      router.push({ name: redirectRoute });
    } else {
      dispatch(
        `${Modules.DATA_GRIDS}/${Actions.DATAGRID_FETCH_WITH_CURRENT_PARAMS}`,
        {
          dataGrid,
          fetchActionId: dataGridFetch,
        },
        { root: true }
      );
    }

    toggleDialog(dialog, {});
    pushNotify(Notify.SUCCESS, `${prefix} removed successfully`);
  },

  [Actions.INSTANCE_FETCH_CSV_STATISTICS]: async (
    _,
    { actionId, url, params }
  ) => {
    const csvRes = await createRequest({
      action: actionId,
      url,
      method: "GET",
      responseType: "blob",
      params,
    });

    const { data, errors } = parseResponse(csvRes);

    if (errors.length === 0) {
      downloadFile(data, "test", "csv");
    } else {
      pushNotify(Notify.ERROR, generateErrorMsg(actionId));
    }
  },

  [Actions.DIAL_LOG_FETCH]: async (_, { guid }) => {
    const recordRes = await createRequest({
      action: Actions.DIAL_LOG_FETCH,
      method: "GET",
      url: ApiEndpoints.DIAL_LOG_DETAIL,
      params: { guid },
    });

    const { errors, data } = parseResponse(recordRes);

    if (errors.length === 0) {
      return data;
    }
  },

  [Actions.AUDIO_RECORD_FETCH]: async (_, { guid }) => {
    const requestRes = await createRequest({
      action: Actions.AUDIO_RECORD_FETCH,
      method: "GET",
      url: ApiEndpoints.AUDIO_RECORD,
      params: { guid },
      responseType: "blob",
    });

    const { errors, data } = parseResponse(requestRes);

    if (errors.length === 0) {
      const audioUrl = window.URL.createObjectURL(data);
      toggleDialog(Dialogs.DIAL_LOG_AUDIO, { audio: audioUrl, blob: data });
    } else {
      pushNotify(Notify.ERROR, generateErrorMsg(Actions.AUDIO_RECORD_FETCH));
    }
  },

  [Actions.GENERATE_CSV_REPORTS]: async (
    _,
    { id, prefix, dialog, url, from, to, encoding }
  ) => {
    const recordRes = await createRequest({
      action: Actions.GENERATE_CSV_REPORTS,
      method: "GET",
      url: `${url}/${id}`,
      params: { id, from: new Date(from), to: new Date(to), encoding },
      responseType: "blob",
    });

    const { errors, data } = parseResponse(recordRes);

    if (errors.length === 0) {
      pushNotify(Notify.SUCCESS, "Report generated successfully");
      toggleDialog(dialog);
      downloadFile(
        data,
        `report-${prefix}_id_${id}-from_${from}-to_${to}`,
        "csv"
      );
      setStorageItem("csvEncoding", encoding);
    }
  },
};

export default instanceActions;
