import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";
import ExternalLinks from "@/constants/externalLinks";
import { Namespaces, Permissions } from "@/constants/permissions";
import Routes from "@/constants/routes";
import hasUserPermissions from "@/helpers/hasUserPermissions";
import toggleDialog from "@/helpers/toggleDialog";
import useFlowActions from "@/hooks/useFlowActions";
import { Actions, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { mapActions } from "vuex";
import CallScriptsDataGrid from "../CallScriptsDataGrid";

const FlowsDataGrid = Vue.extend({
  data: () => ({
    extendedConfig: {},
  }),

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [
      Actions.CALLSCRIPTS_DATAGRID_FETCH,
      Actions.FILTER_FAVORITE_ITEMS,
    ]),
  },

  created() {
    this.extendedConfig = {
      title: "Conversation flows",
      buttons: [
        {
          icon: "mdi-star-outline",
          action: () =>
            this.FILTER_FAVORITE_ITEMS({
              dataGrid: DataGrids.CALL_SCRIPTS,
              actionId: Actions.CALLSCRIPTS_DATAGRID_FETCH,
            }),
          isIconButton: true,
          iconActive: "mdi-star",
        },

        {
          title: "New flow",
          icon: "mdi-plus",
          isAllowed: hasUserPermissions(Namespaces.CALLSCRIPT, [
            Permissions.CREATE,
          ]),
          action: () => toggleDialog(Dialogs.FLOW_CREATE, {}),
        },
      ],
      rowActions: (row: any) => ({
        primary: {
          icon: "mdi-file-tree",
          title: "Flow design",
          isAllowed: hasUserPermissions(Namespaces.CALLSCRIPT, [
            Permissions.VIEW_CALLSCRIPT,
          ]),
          action: () =>
            window
              .open(`${ExternalLinks.DESIGNER}?scenarioId=${row.id}`, "_self")
              ?.focus(),
        },
        secondary: [
          {
            icon: "mdi-eye",
            title: "Detail",
            isAllowed: hasUserPermissions(Namespaces.CALLSCRIPT, [
              Permissions.VIEW,
            ]),
            action: () =>
              this.$router.push({
                name: Routes.FLOW_DETAIL_BASIC,
                params: { idCallScript: row.id },
              }),
          },
          ...useFlowActions(row),
        ],
      }),
    };
  },

  render(): VNode {
    return (
      <CallScriptsDataGrid
        extendedConfig={this.extendedConfig}
        fetchProps={{ isComponent: false }}
      />
    );
  },
});

export default FlowsDataGrid;
