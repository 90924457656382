import Vue, { VNode, PropType } from "vue";
import {
  VDataTable,
  VToolbarTitle,
  VBtn,
  VIcon,
  VMenu,
  VList,
  VListItem,
  VListItemIcon,
  VListItemContent,
  VListItemTitle,
  VToolbar,
  VSpacer,
  VTooltip,
  VPagination,
  VDivider,
  VCard,
  VCardText,
  VTextField,
  VSelect,
  VDatePicker,
  VForm,
  VChip,
} from "vuetify/lib";
import RowActionButton from "./RowActionButton";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import DataGridButton from "@/models/dataGrid/DataGridButton";
import DataGridsState from "@/models/store/DataGridsState";
import DataGridRowAction from "@/models/dataGrid/DataGridRowAction";
import "./DataGrid.scss";

const DataGrid = Vue.extend({
  props: {
    data: Object as PropType<DataGridsState>,
    configuration: Object as PropType<DataGridConfig>,
    fetchData: Function,
    fetchProps: Object,
    isFetching: Boolean,
    isFavoriteActive: Boolean,
    withSearch: Boolean,
  },

  data: () => ({
    modificators: {
      sortBy: "",
      sortDirection: "",
      page: 1,
      search: "" as any,
    },
    filters: {
      values: {},
      active: [],
      menus: {},
      datepickers: {},
    },
    headers: {},
  }),

  created(): void {
    this.data.items = [];
    this.data.pagination = { page: 1, pages: 1 };

    this.initFiltersFromQuery();
    this.initHeaders();

    this.modificators = {
      ...this.modificators,
      ...(this.fetchProps && { params: this.fetchProps }),
      ...(this.filters.values && { filters: this.filters.values }),
    };

    this.fetchData ? this.fetchData(this.modificators) : null;
  },

  methods: {
    initFiltersFromQuery(): void {
      const { page, sort, search, ...filters } = this.$route.query;
      const querySort = sort ? String(sort).split(":") : null;

      this.modificators.page = page ? Number(page) : this.modificators.page;
      this.modificators.sortBy = querySort
        ? querySort[0]
        : this.modificators.sortBy;
      this.modificators.sortDirection = querySort
        ? querySort[1]
        : this.modificators.sortDirection;

      this.modificators.search = search ? search : this.modificators.search;

      for (const [key, val] of Object.entries(filters)) {
        this.setFilter(key, val, false);
      }
    },

    focusFilterField(ref: string) {
      const refs: any = this.$refs;

      setTimeout(() => refs[ref]?.$el?.querySelector("input")?.focus(), 100);
    },

    handleSearch() {
      this.modificators.page = 1;
      this.fetchData(this.modificators);
    },

    initHeaders(): void {
      this.configuration.filters?.map(({ name, values, withDatePicker }) =>
        Vue.set(this.headers, `header.${name}`, ({ header }: any) => (
          <span>
            <span class="d-inline-block py-3">{header.text}</span>
            <VMenu
              vModel={this.filters.menus[name]}
              offsetY
              transition="scale-transition"
              closeOnContentClick={false}
              maxWidth="250"
              scopedSlots={{
                activator: ({ on, attrs }: any) => (
                  <span class="float-right mt-1">
                    <VBtn
                      class="tertiary--text"
                      icon
                      {...{ on, attrs }}
                      onClick={(e: Event) => {
                        on.click(e);
                        this.focusFilterField(name);
                      }}
                    >
                      <VIcon small>mdi-filter</VIcon>
                      {this.isFilterActive(name) && (
                        <VIcon
                          color="error"
                          style="position: absolute; top: -8px; right: -5px;"
                          xSmall
                        >
                          mdi-circle
                        </VIcon>
                      )}
                    </VBtn>
                  </span>
                ),
              }}
            >
              <VCard>
                <VCardText>
                  <VForm onSubmit={(e: any) => e.preventDefault()}>
                    {values && (
                      <VSelect
                        items={values}
                        label={header.text}
                        vModel={this.filters.values[name]}
                      ></VSelect>
                    )}

                    {withDatePicker && (
                      <VMenu
                        vModel={this.filters.datepickers[name]}
                        offsetY
                        transition="scale-transition"
                        maxWidth="290"
                        scopedSlots={{
                          activator: ({ on, attrs }: any) => (
                            <VTextField
                              vModel={this.filters.values[name]}
                              label={header.text}
                              readonly
                              prependIcon="mdi-calendar"
                              {...{ on, attrs }}
                            />
                          ),
                        }}
                      >
                        <VDatePicker
                          vModel={this.filters.values[name]}
                          firstDayOfWeek={1}
                        />
                      </VMenu>
                    )}

                    {!values && !withDatePicker && (
                      <VTextField
                        ref={name}
                        label={header.text}
                        vModel={this.filters.values[name]}
                      />
                    )}

                    <div class="d-flex justify-end">
                      <VBtn
                        onClick={() => this.clearFilter(name)}
                        rounded
                        small
                        class={`secondary primary--text ${
                          this.isFilterActive(name) ? "" : "d-none"
                        }`}
                      >
                        <VIcon small>mdi-close</VIcon>
                        Clear
                      </VBtn>
                      <VBtn
                        type="submit"
                        onClick={() =>
                          this.setFilter(name, this.filters.values[name])
                        }
                        rounded
                        small
                        class="tertiary white--text ml-2"
                      >
                        <VIcon small>mdi-check</VIcon>
                        Apply
                      </VBtn>
                    </div>
                  </VForm>
                </VCardText>
              </VCard>
            </VMenu>
          </span>
        ))
      );
    },

    getHeadersWithSortableOptions(): any[] {
      const { data, configuration } = this;

      if (!data.sortable) {
        return configuration.headers;
      }

      configuration.headers.map(({ value }, index) => {
        const isSortableProperty = data.sortable?.find(
          (property) => property === value
        );
        const currentProperty = configuration.headers[index];
        isSortableProperty
          ? (currentProperty.sortable = true)
          : (currentProperty.sortable = false);
      });

      return configuration.headers;
    },

    setSortProperty(property: string): void {
      property
        ? (this.modificators.sortBy = property)
        : (this.modificators.sortBy = "");
    },

    setSortDirection(direction: boolean): void {
      if (!this.fetchData) {
        return;
      }

      direction
        ? (this.modificators.sortDirection = "desc")
        : (this.modificators.sortDirection = "asc");

      this.fetchData(this.modificators);
    },

    setFilter(name: string, value: any, withDataFetch = true): void {
      const filters: any = this.filters;

      filters.values[name] = value;
      filters.active.push(name);
      withDataFetch ? this.fetchData(this.modificators) : null;
      filters.menus[name] = false;
    },

    clearFilter(name: string): void {
      const filters: any = this.filters;
      const filterIndex = this.filters.active.findIndex(
        (filter) => filter === name
      );
      filters.active.splice(filterIndex, 1);
      filters.values[name] = null;
      this.fetchData(this.modificators);
      filters.menus[name] = false;
    },

    isFilterActive(name: string): boolean {
      const filters: any = this.filters;
      return filters.active.includes(name);
    },

    changePage(pageNumber: number): void {
      this.modificators.page = pageNumber;
      this.fetchData(this.modificators);
    },

    isActionAllowed(action: DataGridRowAction): boolean {
      if (action.isAllowed === undefined) {
        return true;
      }

      return action.isAllowed || false;
    },

    isAnyActionAllowed(actions: DataGridRowAction[]): boolean {
      const allowedAction = actions.find((action) =>
        this.isActionAllowed(action)
      );

      return !!allowedAction;
    },
  },

  render(): VNode {
    const { data, configuration, isFetching, isFavoriteActive } = this;

    return (
      <div
        class={`data-grid ${
          data.highlights?.length || 0 > 0 ? "data-grid--search-view" : ""
        }`}
      >
        {configuration.isHeaderOutside && (
          <div class="d-flex mb-5 align-center">
            <h2 class="text-h4 font-weight-bold primary--text">
              {configuration.title}
            </h2>
            <VSpacer />
            {this.withSearch && (
              <VForm
                onSubmit={(e: Event) => {
                  e.preventDefault();
                  this.handleSearch();
                }}
              >
                <VTextField
                  vModel={this.modificators.search}
                  class="shrink"
                  outlined
                  rounded
                  dense
                  hideDetails
                  label="Search"
                  appendIcon="mdi-magnify"
                  on={{
                    "click:append": () => this.handleSearch(),
                  }}
                />
              </VForm>
            )}
            {configuration.buttons &&
              configuration.buttons.map(
                ({
                  title,
                  icon,
                  isIconButton,
                  iconActive,
                  action,
                  isAllowed = true,
                }: DataGridButton) =>
                  isAllowed && (
                    <div style="position: relative">
                      <VBtn
                        color="tertiary"
                        onClick={action}
                        class={`ml-2 ${
                          isIconButton ? "tertiary--text" : "secondary--text"
                        }`}
                        rounded={!isIconButton}
                        icon={isIconButton}
                      >
                        <VIcon left={!isIconButton}>
                          {isFavoriteActive && iconActive ? iconActive : icon}
                        </VIcon>
                        {title}
                      </VBtn>

                      {isIconButton && isFavoriteActive && (
                        <VIcon
                          color="error"
                          style="position: absolute; top: 1px; right: 1px; pointer-events: none"
                          xSmall
                        >
                          mdi-circle
                        </VIcon>
                      )}
                    </div>
                  )
              )}
          </div>
        )}
        <VDataTable
          headers={[
            ...this.getHeadersWithSortableOptions(),
            ...(configuration.rowActions
              ? [
                  {
                    text: "Actions",
                    value: "actions",
                    sortable: false,
                    width: 120,
                  },
                ]
              : []),
          ]}
          expanded={data.items}
          items={data.items}
          hideDefaultFooter
          class={configuration.disableElevation ? "" : "elevation-1"}
          loading={this.isFetching}
          loadingText="Loading data..."
          scopedSlots={{
            footer: () => (
              <div>
                {data.pagination && data.pagination?.pages > 1 && (
                  <div>
                    <VDivider />
                    <VPagination
                      vModel={data.pagination.page}
                      color="tertiary white--text"
                      length={data.pagination.pages}
                      totalVisible={7}
                      class="mt-5 pb-3"
                      disabled={isFetching}
                      onInput={(page: number) => this.changePage(page)}
                    />
                  </div>
                )}
              </div>
            ),
            top: () =>
              configuration.title && !configuration.isHeaderOutside ? (
                <VToolbar flat class="mb-2">
                  {configuration.title && (
                    <VToolbarTitle
                      class="primary--text font-weight-bold text-h5"
                      inset
                      vertical
                    >
                      {configuration.title}
                    </VToolbarTitle>
                  )}
                  <VSpacer />
                  {this.withSearch && (
                    <VForm
                      onSubmit={(e: Event) => {
                        e.preventDefault();
                        this.handleSearch();
                      }}
                    >
                      <VTextField
                        vModel={this.modificators.search}
                        class="shrink"
                        outlined
                        rounded
                        dense
                        hideDetails
                        label="Search"
                        appendIcon="mdi-magnify"
                        on={{
                          "click:append": () => this.handleSearch(),
                        }}
                      />
                    </VForm>
                  )}
                  {configuration.buttons &&
                    configuration.buttons.map(
                      ({
                        title,
                        icon,
                        action,
                        isFab,
                        isAllowed = true,
                      }: DataGridButton) =>
                        isAllowed && (
                          <VBtn
                            color="tertiary"
                            onClick={action}
                            class="ml-2 secondary--text"
                            rounded
                            fab={isFab}
                            small={isFab}
                          >
                            <VIcon left={!isFab}>{icon}</VIcon>
                            {!isFab && title}
                          </VBtn>
                        )
                    )}
                </VToolbar>
              ) : null,
            "expanded-item": ({ headers, item }: any) =>
              data.highlights?.length || 0 > 0 ? (
                <td colspan={headers.length}>
                  <VChip color="light">
                    <span
                      class="gray--text"
                      domPropsInnerHTML={
                        this.data.highlights?.[data.items?.indexOf(item) || 0]
                      }
                    ></span>
                  </VChip>
                </td>
              ) : null,
            "item.actions": ({ item }: never) => {
              if (configuration.rowActions) {
                const rowActions = configuration.rowActions(item);

                return (
                  <div>
                    {rowActions.primary &&
                      this.isActionAllowed(rowActions.primary) && (
                        <VTooltip
                          left
                          scopedSlots={{
                            activator: ({ on, attrs }: never) => (
                              <RowActionButton
                                primary
                                disabled={
                                  isFetching || rowActions.primary.disabled
                                }
                                icon={rowActions?.primary.icon}
                                slotProps={{ on, attrs }}
                                action={() => rowActions?.primary.action(item)}
                              />
                            ),
                          }}
                        >
                          <span>{rowActions.primary.title}</span>
                        </VTooltip>
                      )}
                    {rowActions.secondary &&
                      this.isAnyActionAllowed(rowActions.secondary) && (
                        <span>
                          {rowActions.secondary.length === 1 &&
                          this.isActionAllowed ? (
                            <VTooltip
                              right
                              scopedSlots={{
                                activator: ({ on, attrs }: never) => (
                                  <RowActionButton
                                    disabled={
                                      isFetching ||
                                      rowActions?.secondary?.[0].disabled
                                    }
                                    icon={rowActions?.secondary?.[0].icon}
                                    slotProps={{ on, attrs }}
                                    action={() =>
                                      rowActions?.secondary?.[0].action(item)
                                    }
                                  />
                                ),
                              }}
                            >
                              <span>{rowActions.secondary[0].title}</span>
                            </VTooltip>
                          ) : (
                            <VMenu
                              offset-y
                              scopedSlots={{
                                activator: ({ on, attrs }: never) => (
                                  <RowActionButton
                                    disabled={isFetching}
                                    icon="mdi-dots-horizontal"
                                    slotProps={{ on, attrs }}
                                  />
                                ),
                              }}
                            >
                              <VList dense minWidth="200">
                                {[
                                  rowActions.primary,
                                  ...rowActions.secondary,
                                ].map(
                                  (action) =>
                                    this.isActionAllowed(action) && (
                                      <VListItem
                                        onClick={() => action.action(item)}
                                        disabled={action.disabled}
                                      >
                                        <VListItemIcon>
                                          <VIcon color="primary">
                                            {action.icon}
                                          </VIcon>
                                        </VListItemIcon>
                                        <VListItemContent>
                                          <VListItemTitle>
                                            {action.title}
                                          </VListItemTitle>
                                        </VListItemContent>
                                      </VListItem>
                                    )
                                )}
                              </VList>
                            </VMenu>
                          )}
                        </span>
                      )}
                  </div>
                );
              }
            },
            ...this.headers,
            ...(configuration.columnModificators || {}),
          }}
          on={{
            "update:sort-by": (properties: string[]) =>
              this.setSortProperty(properties[0]),

            "update:sort-desc": (sortDesc: boolean[]) =>
              this.setSortDirection(sortDesc[0]),
          }}
          {...(this.fetchData
            ? { serverItemsLength: data.items?.length || 0 }
            : {})}
        />
      </div>
    );
  },
});

export default DataGrid;
