import Vue, { VNode } from "vue";
import DataGrid from "../DataGrid";
import DataGridConfig from "@/models/dataGrid/DataGridConfig";
import { mapActions, mapGetters } from "vuex";
import { Actions, Getters, Modules } from "@/models/store";
import DataGrids from "@/constants/dataGrids";
import { formatDate } from "@/helpers/dateAndTimeUtils";
import { formatTime } from "@/helpers/dateAndTimeUtils";
import { VIcon } from "vuetify/lib";
import toggleDialog from "@/helpers/toggleDialog";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import { CALL_STATUS_ICONS } from "@/constants/valueIcons";
import DialLogDetailDialog from "../DialLogDetailDialog";
import AudioDialog from "../AudioDialog";

const DialLogDataGrid = Vue.extend({
  props: {
    params: {},
    withElevation: {
      type: Boolean,
      default: false,
    },
    showFlow: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    dataGridConfig: {},
  }),

  computed: {
    ...mapGetters(Modules.DATA_GRIDS, [Getters.GET_DATAGRID_DATA]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    ...mapActions(Modules.DATA_GRIDS, [
      Actions.DIAL_LOGS_DATAGRID_FETCH,
      Actions.DIAL_LOG_FETCH,
    ]),
    ...mapActions(Modules.INSTANCES, [
      Actions.DIAL_LOG_FETCH,
      Actions.AUDIO_RECORD_FETCH,
    ]),

    async showLogDetail(guid: string, rowData: any): Promise<void> {
      const rawData = await this.DIAL_LOG_FETCH({ guid });
      toggleDialog(Dialogs.DIAL_LOG_DETAIL, {
        rawData,
        guid,
        ...rowData,
      });
    },

    checkDetailParams(): void {
      if (!this.$route.query.guid) {
        return;
      }

      const guid: any = this.$route.query.guid;
      const idTab: any = this.$route.query.tab || 0;

      this.showLogDetail(guid, { idTab });
    },
  },

  created() {
    const dataGridConfig: DataGridConfig = {
      title: "Conversation log",
      disableElevation: !this.withElevation,
      headers: [
        { text: "Phone", value: "phone" },
        { text: "State", value: "stateName" },
        { text: "Time", value: "callstart" },
        { text: "Direction", value: "direction" },
        { text: "Duration", value: "duration" },
        ...(this.showFlow ? [{ text: "Flow", value: "callScript" }] : []),
      ],
      rowActions: ({ guid, hasRecord, ...rowData }) => ({
        primary: {
          action: () => this.showLogDetail(guid, rowData),
          icon: "mdi-eye",
          title: "Show detail",
        },
        secondary: [
          {
            action: () => this.AUDIO_RECORD_FETCH({ guid }),
            icon: "mdi-volume-high",
            title: "Play record",
            disabled: !hasRecord,
          },
        ],
      }),
      buttons: [
        {
          action: () => this.DIAL_LOGS_DATAGRID_FETCH(this.params),
          isFab: true,
          icon: "mdi-refresh",
        },
      ],
      columnModificators: {
        "item.callstart": ({ item }) => formatDate(item.callstart),
        "item.duration": ({ item }) => formatTime(item.duration),

        "item.direction": ({ item }) =>
          item.direction ? (
            <span>
              <VIcon small class="mr-2" color="warning">
                mdi-phone-outgoing
              </VIcon>
              OUT
            </span>
          ) : (
            <span>
              <VIcon small class="mr-2" color="success">
                mdi-phone-incoming
              </VIcon>
              IN
            </span>
          ),

        "item.callScript": ({ item }) => (
          <router-link
            class="tertiary--text"
            to={{
              name: Routes.FLOW_DETAIL_BASIC,
              params: { idCallScript: item.idCallScript },
            }}
          >
            {item.callScript}
          </router-link>
        ),

        "item.stateName": ({ item }) => (
          <span>
            <VIcon
              small
              class="mr-2"
              color={CALL_STATUS_ICONS[item.state]?.color}
            >
              {CALL_STATUS_ICONS[item.state]?.icon}
            </VIcon>
            {item.stateName}
          </span>
        ),
      },
      filters: [{ name: "phone" }],
    };

    this.dataGridConfig = dataGridConfig;
    this.checkDetailParams();
  },

  render(): VNode {
    return (
      <div>
        <DataGrid
          fetchData={this.DIAL_LOGS_DATAGRID_FETCH}
          fetchProps={this.params}
          data={this.GET_DATAGRID_DATA(DataGrids.DIAL_LOGS)}
          configuration={this.dataGridConfig}
          isFetching={
            this.IS_ACTION_FETCHING(Actions.DIAL_LOGS_DATAGRID_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.AUDIO_RECORD_FETCH) ||
            this.IS_ACTION_FETCHING(Actions.DIAL_LOG_FETCH)
          }
          withSearch
        />

        <DialLogDetailDialog />
        <AudioDialog />
      </div>
    );
  },
});

export default DialLogDataGrid;
