import { Getters, Modules } from "@/models/store";
import store from "@/store";

const hasUserPermissions = (
  namespace: string,
  permissions: string[]
): boolean => {
  if (!namespace && !permissions) {
    return true;
  }

  const nsPermissions =
    permissions.length > 0
      ? permissions.map((permission) => `${namespace}.${permission}`)
      : [namespace];

  return store.getters[`${Modules.USER}/${Getters.HAS_PERMISSIONS}`](
    nsPermissions
  );
};

export default hasUserPermissions;
