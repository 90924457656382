import ApiEndpoints from "@/constants/apiEndpoints";
import Notify from "@/constants/notifications";
import generateErrorMsg from "@/helpers/generateErrorMsg";
import pushNotify from "@/helpers/pushNotify";
import submitForm from "@/helpers/submitForm";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCol,
  VFileInput,
  VForm,
  VIcon,
  VRow,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import SectionToolbar from "../SectionToolbar";

const PackageManagerInstall = Vue.extend({
  data: () => ({
    installPackage: "",
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    async handleSubmit() {
      if (this.installPackage) {
        const formData = new FormData();
        formData.append("file", this.installPackage);

        const { isFormSubmited, data } = await submitForm({
          actionId: Actions.PACKAGE_INSTALL,
          url: ApiEndpoints.PACKAGE_INSTALL,
          values: formData,
        });

        if (isFormSubmited) {
          pushNotify(Notify.SUCCESS, "Package installed successfully.");
        } else {
          pushNotify(Notify.ERROR, generateErrorMsg(Actions.PACKAGE_INSTALL));
        }
      }
    },
  },

  render(): VNode {
    return (
      <div>
        <SectionToolbar title="Install package" />
        <VCard
          elevation="0"
          loading={this.IS_ACTION_FETCHING(Actions.PACKAGE_INSTALL)}
        >
          <VCardText>
            <VForm ref="form">
              <VRow>
                <VCol cols={12} md={6}>
                  <VFileInput
                    vModel={this.installPackage}
                    label="Browse file..."
                    outlined
                  />
                </VCol>
              </VRow>
            </VForm>
          </VCardText>
          <div class="d-flex justify-end">
            <VBtn
              onClick={() => this.handleSubmit()}
              disabled={this.IS_ACTION_FETCHING(Actions.PACKAGE_INSTALL)}
              large
              rounded
              class="tertiary white--text"
            >
              <VIcon left>mdi-package-up</VIcon>Install
            </VBtn>
          </div>
        </VCard>
      </div>
    );
  },
});

export default PackageManagerInstall;
