import Routes from "@/constants/routes";
import Vue, { VNode } from "vue";
import {
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VIcon,
  VList,
  VListItem,
  VSpacer,
} from "vuetify/lib";
import DialLogDataGrid from "../DialLogDataGrid";
import DoughnutChart from "../DoughnutChart";

const CampaignDashboard = Vue.extend({
  data: () => ({
    chartData: {
      labels: ["January", "February", "March"],
      datasets: [{ data: [40, 20, 12] }],
    },
    chartOptions: {
      responsive: true,
    },
  }),
  props: {},
  methods: {},
  render(): VNode {
    return (
      <div class="campaign-dashboard">
        <div class="d-flex align-center">
          <h2 class="text-h4 font-weight-bold primary--text mb-5">
            Frantova kampan
          </h2>
        </div>
        <div class="d-flex">
          <VBtn
            rounded
            class="secondary primary--text"
            onClick={() => this.$router.push({ name: Routes.CAMPAIGN })}
          >
            <VIcon left color="primary">
              mdi-arrow-left
            </VIcon>
            Back
          </VBtn>
          <VSpacer />
          <VBtn rounded class="secondary primary--text">
            <VIcon left color="primary">
              mdi-pencil
            </VIcon>
            Edit
          </VBtn>
          <VBtn rounded class="tertiary white--text ml-3">
            <VIcon left color="white">
              mdi-pause
            </VIcon>
            Pause
          </VBtn>
        </div>
        <div class="d-flex mt-5 mb-5">
          <VCard class="mr-5" style="flex: 1">
            <VCardTitle class="d-flex align-center primary--text font-weight-bold text-h5">
              About campaign
            </VCardTitle>
            <VCardText>
              <VList>
                <VListItem class="px-2">
                  <div>Flow:</div>
                  <VSpacer />
                  <div class="primary--text font-weight-bold">něco</div>
                </VListItem>
                <VListItem class="px-2">
                  <div>Line:</div>
                  <VSpacer />
                  <div class="primary--text font-weight-bold">fdsdgsdfg</div>
                </VListItem>
                <VListItem class="px-2">
                  <div>Tenant:</div>
                  <VSpacer />
                  <div class="primary--text font-weight-bold">Franta</div>
                </VListItem>
                <VListItem class="px-2">
                  <div>Started at:</div>
                  <VSpacer />
                  <div class="primary--text font-weight-bold">
                    18.5.2021 19:52
                  </div>
                </VListItem>
                <VListItem class="px-2">
                  <div>Ends at:</div>
                  <VSpacer />
                  <div class="primary--text font-weight-bold">
                    18.7.2021 19:52
                  </div>
                </VListItem>
                <VListItem class="px-2">
                  <div>Number of call attemps:</div>
                  <VSpacer />
                  <div class="primary--text font-weight-bold">2</div>
                </VListItem>
                <VListItem class="px-2">
                  <div>Retry call after:</div>
                  <VSpacer />
                  <div class="primary--text font-weight-bold">45 min</div>
                </VListItem>
              </VList>
            </VCardText>
          </VCard>
          <div style="flex: 2">
            <div class="d-flex">
              <VCard class="align-self-start mr-5" style="flex: 1">
                <VCardText class="d-flex align-center justify-space-between">
                  <div>
                    <div class="text-h5 success--text font-weight-bold">
                      In progress
                    </div>
                    <div>Status</div>
                  </div>
                  <VIcon class="mr-2" color="success" xLarge>
                    mdi-progress-check
                  </VIcon>
                </VCardText>
              </VCard>
              <VCard class="mr-5 align-self-start" style="flex: 1">
                <VCardText class="d-flex align-center justify-space-between">
                  <div>
                    <div class="text-h5 primary--text font-weight-bold">
                      215
                    </div>
                    <div>Contacts input</div>
                  </div>
                  <VIcon class="mr-2" color="primary" xLarge>
                    mdi-account-box
                  </VIcon>
                </VCardText>
              </VCard>
              <VCard class="align-self-start" style="flex: 1">
                <VCardText class="d-flex align-center justify-space-between">
                  <div>
                    <div class="text-h5 primary--text font-weight-bold">
                      500
                    </div>
                    <div>Calls input</div>
                  </div>
                  <VIcon class="mr-2" color="primary" xLarge>
                    mdi-phone
                  </VIcon>
                </VCardText>
              </VCard>
            </div>
            <div class="d-flex mt-5">
              <VCard class="mr-5" style="flex: 1">
                <VCardText>
                  <DoughnutChart
                    chartData={{
                      labels: ["Picked", "Not Picked"],
                      datasets: [
                        {
                          backgroundColor: ["#17c57c", "#f0545f"],
                          data: [200, 80],
                        },
                      ],
                    }}
                  />
                </VCardText>
              </VCard>
              <VCard style="flex: 1">
                <VCardText>
                  <DoughnutChart
                    chartData={{
                      labels: ["Called", "To call"],
                      datasets: [
                        {
                          backgroundColor: ["#17c57c", "#f0545f"],
                          data: [280, 45],
                        },
                      ],
                    }}
                  />
                </VCardText>
              </VCard>
            </div>
          </div>
        </div>
        <DialLogDataGrid params={{ idCallScript: 213 }} withElevation />
      </div>
    );
  },
});

export default CampaignDashboard;
