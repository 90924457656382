import Dialogs from "@/constants/dialogs";
import Vue, { VNode } from "vue";
import Dialog from "../Dialog";
import VuetifyAudio from "vuetify-audio";
import { mapGetters } from "vuex";
import { Getters, Modules } from "@/models/store";
import downloadFile from "@/helpers/downloadFile";

const AudioDialog = Vue.extend({
  computed: {
    ...mapGetters(Modules.DIALOGS, [
      Getters.GET_DIALOG_DATA,
      Getters.IS_DIALOG_OPEN,
    ]),

    audio(): any {
      const dialogData = this.GET_DIALOG_DATA(Dialogs.DIAL_LOG_AUDIO);
      return { url: dialogData?.audio, blob: dialogData?.blob };
    },
  },

  render(): VNode {
    return (
      <Dialog
        id={Dialogs.DIAL_LOG_AUDIO}
        title="Play audio"
        actions={[
          {
            title: "Download",
            icon: "mdi-download",
            action: () => downloadFile(this.audio.blob, "test", "wav"),
          },
        ]}
      >
        <VuetifyAudio flat file={this.audio.url} autoPlay color="tertiary" />
      </Dialog>
    );
  },
});

export default AudioDialog;
