import { ActionTree } from "vuex";
import { Actions, Mutations } from "@/models/store";
import InstancesState from "@/models/store/InstancesState";
import ApiEndpoints from "@/constants/apiEndpoints";
import createRequest from "@/helpers/createRequest";
import parseResponse from "@/helpers/parseResponse";
import Routes from "@/constants/routes";
import DataGrids from "@/constants/dataGrids";
import Dialogs from "@/constants/dialogs";

const tenantActions: ActionTree<InstancesState, []> = {
  [Actions.TENANT_OVERVIEW_FETCH]: (
    { state, commit, dispatch },
    { idTenant }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.TENANT_BASIC,
      action: Actions.TENANT_OVERVIEW_FETCH,
      params: { idTenant },
      withTitleChange: true,
      titleProp: "invoicingName",
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_TENANT, {
          ...state.inspectedTenant,
          basic: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_TENANT, {}),
    });
  },

  [Actions.TENANT_PARAMS_FETCH]: (
    { state, commit, dispatch },
    { idTenant }
  ) => {
    dispatch(Actions.SECTION_DETAIL_FETCH, {
      url: ApiEndpoints.TENANT_PARAMETERS,
      action: Actions.TENANT_PARAMS_FETCH,
      params: { idTenant },
      modify: (data: any) =>
        commit(Mutations.SET_INSPECTED_TENANT, {
          ...state.inspectedTenant,
          parameters: data,
        }),
      error: () => commit(Mutations.SET_INSPECTED_TENANT, {}),
    });
  },

  [Actions.TENANT_FETCH_NOTIFICATION_GROUPS]: async (_, { idTenant }) => {
    const requestRes = await createRequest({
      action: Actions.TENANT_FETCH_NOTIFICATION_GROUPS,
      method: "GET",
      url: ApiEndpoints.TENANT_USER_NOTIFY_GROUPS,
      params: { idTenant },
    });

    const { data, errors } = parseResponse(requestRes);

    if (errors.length > 0) {
      return {};
    } else {
      return data;
    }
  },

  [Actions.TENANT_REMOVE]: ({ dispatch }, { idTenant, isDetailActive }) =>
    dispatch(Actions.INSTANCE_REMOVE, {
      params: { idTenant },
      isDetailActive,
      endpoint: ApiEndpoints.TENANT_REMOVE,
      action: Actions.TENANT_REMOVE,
      redirectRoute: Routes.TENANTS_OVERVIEW,
      dataGrid: DataGrids.TENANTS,
      dataGridFetch: Actions.TENANTS_DATAGRID_FETCH,
      dialog: Dialogs.TENANT_REMOVE,
      prefix: "Tenant",
    }),

  [Actions.TENANT_STATISTICS_FETCH]: async (
    { state, commit },
    { idTenant, ...params }
  ) => {
    const statisticsRes = await createRequest({
      action: Actions.TENANT_STATISTICS_FETCH,
      url: `${ApiEndpoints.TENANT_STATISTICS}/${idTenant}`,
      method: "GET",
      params,
    });

    const { data, errors } = parseResponse(statisticsRes);

    if (errors.length === 0) {
      commit(Mutations.SET_INSPECTED_TENANT, {
        ...state.inspectedTenant,
        statistics: data,
      });
    }
  },

  [Actions.TENANT_CSV_STATISTICS_FETCH]: async (
    { dispatch },
    { idTenant, from, to }
  ) => {
    dispatch(Actions.INSTANCE_FETCH_CSV_STATISTICS, {
      actionId: Actions.TENANT_CSV_STATISTICS_FETCH,
      url: ApiEndpoints.CSV_TENANT_STATISTICS,
      params: { idTenant, from, to },
    });
  },

  [Actions.GENERATE_TENANT_CSV_STATISTICS_FETCH]: async (
    { dispatch },
    { idTenant, from, to, roundInterval }
  ) => {
    dispatch(Actions.INSTANCE_FETCH_CSV_STATISTICS, {
      actionId: Actions.GENERATE_TENANT_CSV_STATISTICS_FETCH,
      url: ApiEndpoints.CSV_OVERVIEW_TENANT_STATISTICS,
      params: { idTenant, from, to, roundInterval },
    });
  },
};

export default tenantActions;
