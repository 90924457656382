import CountryFlag from "vue-country-flag";
import Dialogs from "@/constants/dialogs";
import Routes from "@/constants/routes";
import getCountryCode from "@/helpers/getCountryCode";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VDivider } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import EditableOverviewSection from "../EditableOverviewSection";
import LineBasicEditForm from "../LineBasicEditForm";
import LineCallbotConfigurationForm from "../LineCallbotConfigurationForm";

const LineOverview = Vue.extend({
  data: () => ({
    basic: {},
    callbotConfiguration: {
      title: "Advanced configuration",
      columns: [
        {
          name: "MaxInboundCalls",
          title: "Max Inbound Calls",
        },
        {
          name: "MaxOutboundCalls",
          title: "Max Outbound Calls",
        },
      ],
    },
  }),

  computed: {
    ...mapGetters(Modules.INSTANCES, [Getters.INSPECTED_LINE]),
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),

    idDialModuleLine() {
      return this.$route.params.idDialModuleLine;
    },
  },

  methods: {
    ...mapActions(Modules.INSTANCES, [
      Actions.LINE_OVERVIEW_FETCH,
      Actions.LINE_CALLBOT_CONFIG_FETCH,
    ]),
  },

  created() {
    this.basic = {
      title: "Overview",
      columns: [
        { name: "name", title: "Name" },
        { name: "callScript", title: "Call script" },
        { name: "dialModule", title: "Module" },
        { name: "tenant", title: "Tenant" },
        { name: "identificator", title: "Identificator" },
        { name: "identificatorType", title: "Identificator type" },
      ],
      modificators: {
        dialModule: ({ idDialModule, dialModule }: any) => (
          <router-link
            class="tertiary--text"
            to={{
              name: Routes.MODULE_DETAIL_BASIC,
              params: { idDialModule },
            }}
          >
            {dialModule}
          </router-link>
        ),
        callScript: ({ callScript, idCallScript, languageCode }: any) => (
          <div class="d-flex align-center">
            <router-link
              class="tertiary--text"
              to={{
                name: Routes.FLOW_DETAIL_BASIC,
                params: { idCallScript },
              }}
            >
              {callScript}
            </router-link>
            {languageCode && (
              <div class="d-flex align-center ml-2">
                (
                <CountryFlag
                  size="small"
                  country={getCountryCode(languageCode)}
                />
                <span class="pl-1">{languageCode}</span>)
              </div>
            )}
          </div>
        ),
      },
    };
  },

  render(): VNode {
    return (
      <div>
        <EditableOverviewSection
          configuration={this.basic}
          data={this.INSPECTED_LINE?.basic}
          editDialog={Dialogs.LINE_EDIT_BASIC}
          action={Actions.LINE_OVERVIEW_EDIT}
          scopedSlots={{ form: () => <LineBasicEditForm ref="form" /> }}
          isDataFetching={this.IS_ACTION_FETCHING(Actions.LINE_OVERVIEW_FETCH)}
        />

        <VDivider class="mb-5" />

        <EditableOverviewSection
          configuration={this.callbotConfiguration}
          data={this.INSPECTED_LINE?.callbotSettings}
          fetchData={() =>
            this.LINE_CALLBOT_CONFIG_FETCH({
              idDialModuleLine: this.idDialModuleLine,
            })
          }
          action={Actions.LINE_CALLBOT_CONFIG_EDIT}
          editDialog={Dialogs.LINE_EDIT_CALLBOT_CONFIG}
          scopedSlots={{
            form: () => <LineCallbotConfigurationForm ref="form" />,
          }}
          isDataFetching={this.IS_ACTION_FETCHING(
            Actions.LINE_CALLBOT_CONFIG_FETCH
          )}
        />
      </div>
    );
  },
});

export default LineOverview;
