import submitForm from "@/helpers/submitForm";
import ApiEndpoints from "@/constants/apiEndpoints";
import Dialogs from "@/constants/dialogs";
import Notify from "@/constants/notifications";
import Routes from "@/constants/routes";
import pushNotify from "@/helpers/pushNotify";
import toggleDialog from "@/helpers/toggleDialog";
import { hasMinLength, isEqualTo, isRequired } from "@/helpers/validations";
import { Actions, Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import { VForm, VTextField } from "vuetify/lib";
import { mapGetters } from "vuex";

const ChangePasswordForm = Vue.extend({
  data: () => ({
    fields: {
      password: "",
      passwordAgain: "",
    },
    isPasswordHidden: true,
    isFormValid: true,
  }),

  computed: {
    ...mapGetters(Modules.DIALOGS, [Getters.GET_DIALOG_DATA]),

    idUser(): number {
      return this.GET_DIALOG_DATA(Dialogs.CHANGE_PASSWORD)?.idUser;
    },
  },

  methods: {
    async handleSubmit() {
      const { isFormSubmited } = await submitForm({
        ref: this.$refs.form,
        url: ApiEndpoints.TENANT_USER_PASSWORD,
        values: { password: this.fields.password },
        actionId: Actions.USER_PASSWORD_CHANGE,
        params: { idUser: this.idUser },
      });
      if (isFormSubmited) {
        toggleDialog(Dialogs.CHANGE_PASSWORD, {});
        pushNotify(Notify.SUCCESS, "Password changed successfully");
      }
    },
  },

  render(): VNode {
    return (
      <VForm vModel={this.isFormValid} ref="form">
        <VTextField
          vModel={this.fields.password}
          label="Password"
          type={this.isPasswordHidden ? "password" : "text"}
          rules={[isRequired, (val: string) => hasMinLength(val, 7)]}
          appendIcon={this.isPasswordHidden ? "mdi-eye" : "mdi-eye-off"}
          on={{
            "click:append": () =>
              (this.isPasswordHidden = !this.isPasswordHidden),
          }}
        />
        <VTextField
          vModel={this.fields.passwordAgain}
          label="Password again"
          type="password"
          rules={[(val: string) => isEqualTo(val, this.fields.password)]}
        />
      </VForm>
    );
  },
});

export default ChangePasswordForm;
