import Dialogs from "@/constants/dialogs";
import DialogsState from "@/models/store/DialogsState";

const initDialogState = (): any => ({
  isActive: false,
  data: {},
});

const state: DialogsState = {
  [Dialogs.MODULE_EDIT_BASIC]: initDialogState(),
  [Dialogs.MODULE_CALLBOT_CONFIGURATION]: initDialogState(),
  [Dialogs.MODULE_CSV_EXPORT]: initDialogState(),
  [Dialogs.MODULE_REMOVE]: initDialogState(),
  [Dialogs.LINE_CSV_EXPORT]: initDialogState(),
  [Dialogs.LINE_CHANGE_MODULE]: initDialogState(),
  [Dialogs.LINE_CREATE]: initDialogState(),
  [Dialogs.FLOW_CSV_EXPORT]: initDialogState(),
  [Dialogs.DIAL_LOG_DETAIL]: initDialogState(),
  [Dialogs.FLOW_SWITCH_VERSION]: initDialogState(),
  [Dialogs.FLOW_CREATE]: initDialogState(),
  [Dialogs.FLOW_VERSION_DIFF]: initDialogState(),
  [Dialogs.FLOW_DUPLICATE]: initDialogState(),
  [Dialogs.FLOW_REMOVE]: initDialogState(),
  [Dialogs.FLOW_EDIT_BASIC]: initDialogState(),
  [Dialogs.LINE_EDIT_BASIC]: initDialogState(),
  [Dialogs.LINE_ENABLE]: initDialogState(),
  [Dialogs.LINE_REMOVE]: initDialogState(),
  [Dialogs.MODULE_SET_DEFAULT_LINE]: initDialogState(),
  [Dialogs.GRAMMAR_CREATE]: initDialogState(),
  [Dialogs.PACKAGE_INSTALL]: initDialogState(),
  [Dialogs.DIAL_LOG_AUDIO]: initDialogState(),
  [Dialogs.TENANT_CREATE]: initDialogState(),
  [Dialogs.GRAMMAR_DUPLICATE]: initDialogState(),
  [Dialogs.GRAMMAR_REMOVE]: initDialogState(),
  [Dialogs.GRAMMAR_EDIT_CONTENT]: initDialogState(),
  [Dialogs.GRAMMAR_EDIT_BASIC]: initDialogState(),
  [Dialogs.USER_CREATE]: initDialogState(),
  [Dialogs.USER_ROLES]: initDialogState(),
  [Dialogs.USER_EDIT_BASIC]: initDialogState(),
  [Dialogs.TENANT_NOTIFY_GROUP]: initDialogState(),
  [Dialogs.LINE_MONITORING]: initDialogState(),
  [Dialogs.USER_REMOVE]: initDialogState(),
  [Dialogs.TENANT_REMOVE]: initDialogState(),
  [Dialogs.USER_CONNECTED_ACCOUNTS]: initDialogState(),
  [Dialogs.CHANGE_PASSWORD]: initDialogState(),
  [Dialogs.LINE_EDIT_CALLBOT_CONFIG]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_CREATE]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_REMOVE]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_EDIT_BASIC]: initDialogState(),
  [Dialogs.PERMISSION_GROUP_PERMISSIONS_EDIT]: initDialogState(),
  [Dialogs.USER_API_SECRET_REMOVE]: initDialogState(),
  [Dialogs.TENANT_EDIT_BASIC]: initDialogState(),
  [Dialogs.TENANT_EDIT_PARAMS]: initDialogState(),
  [Dialogs.CAMPAIGN_CREATE]: initDialogState(),
};

export default state;
