import {
  formatDateForDatePicker,
  getFirstDayCurrentMonth,
  getLastDayCurrentMonth,
} from "@/helpers/dateAndTimeUtils";
import { Getters, Modules } from "@/models/store";
import Vue, { VNode } from "vue";
import {
  VCard,
  VCardText,
  VCardTitle,
  VDivider,
  VSkeletonLoader,
} from "vuetify/lib";
import { mapGetters } from "vuex";
import StatisticsCallDirectionChart from "./StatisticsCallDirectionChart";
import StatisticsCards from "./StatisticsCards";
import StatisticsControlPanel from "./StatisticsControlPanel";
import StatisticsLineDataGrid from "./StatisticsLinesDataGrid";

const StatisticsDashboard = Vue.extend({
  props: {
    data: Object,
    fetchData: Function,
    fetchCsvData: Function,
    fetchParams: Object,
    fetchAction: String,
    fetchCsvAction: String,
    withTenantSelection: {
      type: Boolean,
      default: false,
    },
    fetchCsvReports: Function,
    fetchCsvReportAction: String,
  },

  data: () => ({
    defaultFilters: {
      from: formatDateForDatePicker(getFirstDayCurrentMonth()),
      to: formatDateForDatePicker(getLastDayCurrentMonth()),
      roundInterval: "60",
      idTenant: "",
    },
    menus: {
      from: false,
      to: false,
    },
  }),

  computed: {
    ...mapGetters(Modules.API_CALLS, [Getters.IS_ACTION_FETCHING]),
  },

  methods: {
    async fetchStatistics(filters?: any) {
      const refs: any = this.$refs;
      this.$router.replace({ query: filters }).catch(() => null);

      const fetchParams = this.withTenantSelection
        ? { ...this.fetchParams, ...filters }
        : { ...filters, ...this.fetchParams };

      await this.fetchData({
        ...fetchParams,
        ...(this.withTenantSelection &&
          !filters.idTenant && {
            idTenant: refs?.controlPanel?.getTenantFilter(),
          }),
      });
      refs.cards.fetchCardData(this.data);
    },
  },

  created() {
    this.defaultFilters = { ...this.defaultFilters, ...this.$route.query };
    this.withTenantSelection && !this.defaultFilters.idTenant
      ? null
      : this.fetchStatistics(this.defaultFilters);
  },

  render(): VNode {
    return (
      <div class="tenant-statistics">
        <VCard elevation={0}>
          {!this.withTenantSelection && (
            <VCardTitle class="v-toolbar__title primary--text font-weight-bold text-h5 mb-5">
              Total Statistics
            </VCardTitle>
          )}
          <VCardText>
            <StatisticsControlPanel
              ref="controlPanel"
              fetchStatistics={this.fetchStatistics}
              fetchCsvData={this.fetchCsvData}
              fetchParams={this.fetchParams}
              fetchCsvAction={this.fetchCsvAction}
              defaultFilters={this.defaultFilters}
              withTenantSelection={this.withTenantSelection}
              fetchCsvReports={this.fetchCsvReports}
              fetchCsvReportAction={this.fetchCsvReportAction}
            />
          </VCardText>
        </VCard>

        {JSON.stringify(this.data) !== "{}" && <VDivider class="mt-3" />}

        {this.IS_ACTION_FETCHING(this.fetchAction) ? (
          <VSkeletonLoader type="table" />
        ) : (
          <div>
            {JSON.stringify(this.data) !== "{}" && (
              <div>
                <div class="text-center d-flex justify-space-around align-center mt-10">
                  <StatisticsCallDirectionChart
                    highlightIndex={0}
                    data={[
                      (this.data?.totalInboundDuration ?? 0) / 60,
                      (this.data?.totalOutboundDuration ?? 0) / 60,
                    ]}
                  />
                  <div>
                    <div class="text-h6 font-weight-bold">
                      Total calls duration
                    </div>
                    <div class="text-h2 primary--text font-weight-bold">
                      {((this.data?.totalDuration ?? 0) / 60).toLocaleString(
                        "cs"
                      )}
                    </div>
                    <div class="text-h5 primary--text">(min)</div>
                    <div class="text-h6 font-weight-bold mt-10">
                      Total calls
                    </div>
                    <div class="text-h2 primary--text font-weight-bold">
                      {this.data?.totalCalls?.toLocaleString("cs")}
                    </div>
                  </div>
                  <StatisticsCallDirectionChart
                    highlightIndex={1}
                    data={[
                      (this.data?.totalInboundDuration ?? 0) / 60,
                      (this.data?.totalOutboundDuration ?? 0) / 60,
                    ]}
                  />
                </div>
                <StatisticsCards ref="cards" />
                {this.data?.modulesStatistics?.length > 0 && (
                  <div>
                    <VDivider class="mt-10 mb-3" />
                    <VCard elevation={0}>
                      <VCardTitle class="primary--text text-h5 font-weight-bold mb-4 mt-8">
                        Lines statistics
                      </VCardTitle>
                    </VCard>
                    {this.data?.modulesStatistics.map(
                      ({ name, lines }: any) => (
                        <StatisticsLineDataGrid
                          moduleName={name}
                          lines={lines}
                        />
                      )
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  },
});

export default StatisticsDashboard;
